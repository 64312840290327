
<template>
  <div>
    <div>
      <div class="c-buttons-row--container" id="c-buttons-row--container">
        <div class="c-buttons-row">

          <tr>
            <td>
              <h1 v-if="langRUS" style="height:20px; width: calc((100vw - 1100px)/2); text-align: start;">Редактирование счета</h1> <!-- <font size="2">{{account_info.extendinfo}}</font> -->
              <h1 v-if="langENG" style="height:20px; width: calc((100vw - 1100px)/2); text-align: start;">Editing an account</h1>
              <h1 v-if="langIT" style="height:20px; width: calc((100vw - 1100px)/2); text-align: start;">Modifica di un account</h1>
            </td>

            <td v-if="this.account_info.locked == '1'" style="width:390px;"></td>

            <td v-if="this.account_info.locked != '1'">
              <div v-if="mode != 'catalog'" @click="onProductMoveLeft($event);$event.target.blur();" class="c-button">
                <div class="c-button__img"><img src="../assets/LEFT.png" height="30" title="Ctrl+Left"></div>
                <div v-if="langRUS" class="c-button__text">Влево</div>
                <div v-if="langENG" class="c-button__text">Left</div>
                <div v-if="langIT" class="c-button__text">Sinistra</div>
              </div>
              <div v-else @click="onProductMoveUp();$event.target.blur();" class="c-button">
                <div class="c-button__img"><img src="../assets/UP.png" height="30" title="Ctrl+Up"></div>
                <div v-if="langRUS" class="c-button__text">Вверх</div>
                <div v-if="langENG" class="c-button__text">Up</div>
                <div v-if="langIT" class="c-button__text">Su</div>
              </div>
            </td>
            <td v-if="this.account_info.locked != '1'">
              <div v-if="mode != 'catalog'" @click="onProductMoveRight($event);$event.target.blur();" class="c-button">
                <div class="c-button__img"><img src="../assets/RIGHT.png" height="30" title="Ctrl+Right"></div>
                <div v-if="langRUS" class="c-button__text">Вправо</div>
                <div v-if="langENG" class="c-button__text">Right</div>
                <div v-if="langIT" class="c-button__text">Giusto</div>
              </div>
              <div v-else @click="onProductMoveDown();$event.target.blur();" class="c-button">
                <div class="c-button__img"><img src="../assets/DOWN.png" height="30" title="Ctrl+Down"></div>
                <div v-if="langRUS" class="c-button__text">Вниз</div>
                <div v-if="langENG" class="c-button__text">Down</div>
                <div v-if="langIT" class="c-button__text">Giù</div>
              </div>
            </td>
            <td v-if="this.account_info.locked != '1'">
              <div @click="OnAddClick();$event.target.blur();" class="c-button">
                <div class="c-button__img"><img src="../assets/PLUS.png" height="30" title="Ins"></div>
                <div v-if="langRUS" class="c-button__text">Добавить</div>
                <div v-if="langENG" class="c-button__text">Add</div>
                <div v-if="langIT" class="c-button__text">Aggiungere</div>
              </div>
            </td>
            <!--
              <div class="c-button">
                <div class="c-button__img"><img src="../assets/CHANGE.png" height="30"></div>
                <div class="c-button__text">Изменить</div>
              </div>
              -->
            <td v-if="this.account_info.locked != '1'">
              <div @click="onDeleteClick();$event.target.blur();" class="c-button">
                <div class="c-button__img"><img src="../assets/MINUS.png" height="30" title="Shift+Del"></div>
                <div v-if="langRUS" class="c-button__text">Удалить</div>
                <div v-if="langENG" class="c-button__text">Delete</div>
                <div v-if="langIT" class="c-button__text">Eliminare</div>
              </div>
            </td>
            <td v-if="this.account_info.locked != '1'">
              <div @click="onCopyClick($event);$event.target.blur();" class="c-button">
                <div class="c-button__img"><img src="../assets/COPY.png" height="30" title="F9"></div>
                <div v-if="langRUS" class="c-button__text">Скопировать</div>
                <div v-if="langENG" class="c-button__text">Copy</div>
                <div v-if="langIT" class="c-button__text">Copia</div>
              </div>
            </td>
            <td v-if="this.account_info.locked != '1'">
              <div @click="onProductRecalc();$event.target.blur();" class="c-button">
                <div class="c-button__img"><img src="../assets/UPDATE.png" height="30" title="F9"></div>
                <div v-if="langRUS" class="c-button__text">Пересчитать</div>
                <div v-if="langENG" class="c-button__text">Recalculate</div>
                <div v-if="langIT" class="c-button__text">Ricalcolare</div>
              </div>
            </td>
            <td>
              <div @click="onAccountProductsPrint();$event.target.blur();" class="c-button"> <!-- onAccountProductsPrint  onProductsPrint -->
                <div class="c-button__img"><img src="../assets/PRINT.png" height="30" title="F5"></div>
                <div v-if="langRUS" class="c-button__text">КП</div>
                <div v-if="langENG" class="c-button__text">Offer</div>
                <div v-if="langIT" class="c-button__text">Offerta</div>
              </div>
            </td>
            <td>
              <div @click="onAccountProductsSend();$event.target.blur();" class="c-button">
                <div class="c-button__img"><img src="../assets/SEND.png" height="30"></div>
                <div v-if="langRUS" class="c-button__text">Отправить</div>
                <div v-if="langENG" class="c-button__text">Send</div>
                <div v-if="langIT" class="c-button__text">Inviare</div>
              </div>
            </td>
            <td v-if="this.account_info.locked != '1'">
              <div @click="onAccountProductsUpdate(true);$event.target.blur();" class="c-button">
                <div class="c-button__img"><img src="../assets/SAVE.png" height="30" title="F2"></div>
                <div v-if="langRUS" class="c-button__text">Записать</div>
                <div v-if="langENG" class="c-button__text">Save</div>
                <div v-if="langIT" class="c-button__text">Scrivi</div>
              </div>
            </td>
            <td>
              <div @click="onExit();$event.target.blur();" class="c-button">
                <div class="c-button__img"><img src="../assets/EXIT.png" height="30" title="Esc"></div>
                <div v-if="langRUS" class="c-button__text">Выход</div>
                <div v-if="langENG" class="c-button__text">Exit</div>
                <div v-if="langIT" class="c-button__text">Uscita</div>
              </div>
            </td>

            <td v-if="this.account_info.locked == '1'" style="width:390px;"></td>

            <td>
              <h1 style="width: calc((100vw - 1100px)/2); text-align: end;">
              <img @click="OnRus();$event.target.blur();" src="../assets/rus.png" height="20" style="cursor: pointer;" title="Русский">&nbsp;
              <img @click="OnEng();$event.target.blur();" src="../assets/eng.png" height="20" style="cursor: pointer;" title="English">&nbsp;
              <img @click="OnIt();$event.target.blur();" src="../assets/it.png" height="20" style="cursor: pointer;" title="Italiano">&nbsp;
              <!--<img src="../assets/empty.png" height="20">-->
              <img src="../assets/empty.png" height="20">              
              </h1>
            </td>            
          </tr>

        </div>
        <div class="c-buttons-row--container-after">
        </div>
      </div>
      
      <div class="c-container"> 
        <div class="c-acc-table" id="c-acc-table" style="width: calc(100vw - 100px);  height: 110px;">
        <tr style="height: 10px;"></tr>
        <tr style="width:100%;">
          <!--ID: {{account_id}}    -->
          <td style="height: 30px; width: 70px; text-align: end;"><div v-if="langRUS">Дата:</div><div v-if="langENG">Date:</div><div v-if="langIT">Data:</div></td>
          <td style="width: 190px;"><input id="iddate1" type="date" placeholder="dd.mm.yyyy" pattern="[0-9]{1,2}.[0-9]{1,2}.[0-9]{4}" v-on:keyup.escape="onProductPressEsc()" v-on:input="onProductInput($event);" class="c-input" v-model="account_info.date1" style="width: 150px;"/></td>

          <td style="text-align: end;"><div v-if="langRUS">Сделка:</div><div v-if="langENG">Deal:</div><div v-if="langIT">Affare:</div></td>
          <td style="width: 190px;"><input id="iddeal" pattern="\d{1,6}" v-on:keyup.escape="onProductPressEsc()" v-on:input="onProductInput($event);" class="c-input" v-model="account_info.deal" style="width: 150px;"/></td>
          
          <td style="text-align: end;"><div v-if="langRUS">Контрагент:</div><div v-if="langENG">Customer:</div><div v-if="langIT">Acquirente:</div></td>
          <td style="width: 340px;"><input id="idcustomer" v-on:keyup.escape="onProductPressEsc()" v-on:input="onProductInput()" class="c-input" v-model="account_info.customer" style="width:300px;"/></td>
          <td rowspan="3" style="vertical-align: top;"><label>&nbsp;</label>
          <legend style="height: 0; top: -9px; position:relative; font-size: 12px; z-index: 5;">Комментарий</legend>
          <!-- <td style="text-align: end;"><div v-if="langRUS">Комментарий:</div><div v-if="langENG">Comment:</div><div v-if="langIT">Un commento:</div></td> -->
          <textarea id="idcomment" maxlength="1999" v-on:keyup.escape="onProductPressEsc()" v-on:input="onProductInput()" class="c-input" v-model="account_info.comment" 
                    style="height: 70px; width:calc(100vw - 1300px); resize: none;"></textarea>
          </td>
          <td rowspan="3" style="width: 180px; text-align: end;">
            <div v-if="langRUS">Сумма счета:</div><div v-if="langENG">Invoice amount:</div><div v-if="langIT">Importo della fattura:</div>
            <div style="height: 7px;"></div>
            <label for="iddiscount" v-if="langRUS">Скидка </label><label for="iddiscount" v-if="langENG">Discount </label><label for="iddiscount" v-if="langIT">Sconto </label>
            <input type="number" step="1" min="0" max="100" placeholder="0" id="iddiscount" name="iddiscount" v-on:keyup.escape="onProductPressEsc()" v-on:input="onProductInput()" v-on:change="onDiscountChange()" v-model="account_info.discount"
                   style="width: 50px; font-size: 15px; text-align: center;"/><label> %: </label>
            <div style="height: 7px;"></div>
            <div v-if="langRUS">Сумма ИТОГО:</div><div v-if="langENG">Invoice TOTAL:</div><div v-if="langIT">Importo della fattura:</div>
          </td>

          <td rowspan="3" style="text-align: end; width: 80px;">
            <div>{{total}}</div>
            <div style="height: 9px;"></div>
            <div>{{discount_sum}}</div>
            <div style="height: 9px;"></div>
            <div>{{total_discount}}</div>
          </td>
          <td style="width: 10px;"></td>

        </tr> <!-- autocomplete="off" -->
        <tr style="height: 5px; text-align: end;">
        </tr>
        <tr>
          <td style="text-align: end;"><div v-if="langRUS">№ КП:</div><div v-if="langENG">№ Offer:</div><div v-if="langIT">№ Offerta:</div></td>
          <td style="width: 190px;"><input id="idkp" v-on:keyup.escape="onProductPressEsc()" v-on:input="onProductInput()" class="c-input" v-model="account_info.kp" style="width:150px;"/></td>

          <td style="text-align: end;"><div v-if="langRUS">Счет:</div><div v-if="langENG">Account:</div><div v-if="langIT">Controllo:</div></td>
          <td><input id="idaccount" pattern="\d{1,6}" v-on:keyup.escape="onProductPressEsc()" v-on:input="onProductInput()" class="c-input" v-model="account_info.account" style="width:150px;" :class="{isRefresh4}"/></td> <!-- readonly -->

          <td style="text-align: end; position: relative; top: -7px; font-size: 13px;"><div v-if="langRUS">ID<br>контрагента:</div><div v-if="langENG">ID:</div><div v-if="langIT">ID:</div></td>
          <td><input id="idclient_id" pattern="\d{9,9}" v-on:keyup.escape="onProductPressEsc()" v-on:input="onProductInput()" class="c-input" v-model="account_info.client_id" style="width: 300px;" :class="{isRefresh4}"/></td> <!-- v-model="account_info.comment" -->
        </tr>

        </div>

        <div class="divDrawView" id="divDrawView" style="display:none; width:100%; height:300px;">
          <img style="object-fit:contain; width:45%; height:100%; margin:0px 0px 0px 10px;">
          <img style="object-fit:contain; width:45%; height:100%; margin:0px 10px 0px 0px;"><!-- width:100%; -->
        </div>

        <tr class="c-main-table" @click="onTableClick();">
          <td>
            <div class="divImgView" id="divImgView" style="display:none; width:300px; height:300px;">
              <img style="object-fit:contain; width:100%; height:100%;">
            </div>
          </td>

          <td>
          <div ref="table"  id="c-flex-table" class="c-flex-table" :class="{'is-dropdown-opened': select_cursor.active}" v-if="!isFetching"> <!-- v-if="!isFetching" -->
        <!-- Контент таблицы -->

        <!-- Обработка по столбцам (ключам) массива properties, каждый столбец содержит значения по определенному ключу (столбце в таблице бд) -->
        <div ref="sticky-column" v-for="(properties_column, properties_column_name, properties_column_index) in properties" :key="properties_column_name" 
          class="c-flex-table__column c-flex-table__column--sticky-horizontal c-flex-table__column--sticky-horizontal--js" 
          :id="'table__column--sticky-horizontal-' + properties_column_index-1"> <!-- "-1" добавлено только что -->
          <!-- Контент столбца -->

          <div v-if="properties_column_name=='name' && langRUS">
          <!-- Постоянный заголовок -->
          <!-- <div class="c-flex-table_ _header">{{properties_column_name}}</div>-->
          <div v-if="properties_column_name=='idproperties'" class="c-flex-table__header"> ID </div>
          <div v-if="properties_column_name=='name'" class="c-flex-table__header" :class="{'is-pass': !isTable()}">Свойство</div>
          <div v-if="properties_column_name=='key'" class="c-flex-table__header">Ключ</div>
          <!-- Перебираем внутренний !массив! -->
          <div v-for="(item,index) in properties_column" :key="item" class="c-flex-table__data c-flex-table__data--sideheader" :class="{'is-pass': !isTable()}" :properties_row_index="index+1">
            {{item}}
          </div>
          </div>

          <div v-if="properties_column_name=='nameeng' && langENG">
          <!-- Постоянный заголовок -->
          <!-- <div class="c-flex-table_ _header">{{properties_column_name}}</div>-->
          <div v-if="properties_column_name=='idproperties'" class="c-flex-table__header"> ID </div>
          <div v-if="properties_column_name=='nameeng'" class="c-flex-table__header">Property</div>
          <div v-if="properties_column_name=='key'" class="c-flex-table__header">Key</div>
          <!-- Перебираем внутренний !массив! -->
          <div v-for="(item,index) in properties_column" :key="item" class="c-flex-table__data c-flex-table__data--sideheader" :properties_row_index="index+1">
            {{item}}
          </div>
          </div>

          <div v-if="properties_column_name=='nameit' && langIT">
          <!-- Постоянный заголовок -->
          <!-- <div class="c-flex-table_ _header">{{properties_column_name}}</div>-->
          <div v-if="properties_column_name=='idproperties'" class="c-flex-table__header"> ID </div>
          <div v-if="properties_column_name=='nameit'" class="c-flex-table__header">Proprietà</div>
          <div v-if="properties_column_name=='key'" class="c-flex-table__header">Chiave</div>
          <!-- Перебираем внутренний !массив! -->
          <div v-for="(item,index) in properties_column" :key="item" class="c-flex-table__data c-flex-table__data--sideheader" :properties_row_index="index+1">
            {{item}}
          </div>
          </div>


        </div>

        <div v-for="(product, product_index) in productsGGG" :key="product.idproducts" :data-col-index="product_index" class="c-flex-table__column">
          <div class="c-flex-table__header" ref="table-header" style="min-width:150px;"
            :class="{'is-grp1': product.colcolor==1 && (product.isGroup || product.isSubGroup || product.isEndGroup),
                     'is-grp2': product.colcolor==2 && (product.isGroup || product.isSubGroup || product.isEndGroup),
                     'is-cha':  product.change, 'is-cha': product['[pver]'] != '01.11.2024',
                     'is-err':  product.err, isRefresh3}"> <!--'is-cha': product['[pver]'] != '01.10.2024', -->
            <div v-if="langRUS"><div v-if="!product.nnpos" :class="{'is-pass': !isTable()}">Конструкция {{product.namepos}}</div><div v-else>{{product.namepos}}</div></div>
            <!--<br><input type="checkbox" id="scales" name="scales" checked/>--><!--label for="scales">Scales</label-->
            <div v-if="langENG"><div v-if="!product.nnpos">Construct {{product.npos}}</div><div v-else>&lArr;{{product.npos}}:{{product.nnpos}}</div></div>
            <div v-if="langIT"><div v-if="!product.nnpos">Progetto {{product.npos}}</div><div v-else>&lArr;{{product.npos}}:{{product.nnpos}}</div></div>
          </div>

          <!-- Перебираем имена свойств из массива properties.keys и выводим в том же порядке как в properties.keys и только нужные из !объекта! product -->

          <div v-for="(property_name, property_index) in properties.key" :key="property_name" 
          @click="onProductCellClick(product.idproducts, properties.idproperties[property_index]);" 
          :data-cell-col="product.idproducts" :data-cell-row="properties.idproperties[property_index]"
          class="c-flex-table__data"
          :class="{'is-hid' : productsGGG[product_index].options[property_name][0]=='[hid]', 'is-dis' : productsGGG[product_index].options[property_name][0]=='[dis]',
                   'is-serv' : productsGGG[product_index].options[property_name][0]=='[serv]', 'is-req' : properties.required[property_index]=='1', 'is-pass': !isTable(), isRefresh2}">

            {{product[property_name]}}

            <div v-if="productsGGG[product_index].options" class="c-flex-table__select-dropdown" :data-property-name="property_name" :data-product-index="product_index" 
              :data-current-option-pos="getCurrentSelectOptionPos(property_name, product_index)"
              :class="isRefresh">
              <div v-for="(option, option_index) in productsGGG[product_index].options[property_name]" 
                :key="option_index + '_board'" :value="option" @click="onProductSelectOptionClick(option, property_name, product_index)"
                :c-select-dropdown__option_num="option_index"
                class="c-select-dropdown__option">
                {{option}}
              </div>
            </div>
            <div v-else>
              нет опций
            </div>


            

          </div>
        </div>
          </div>
          </td>


        </tr>

        <div id="divSeparateLine" class="c-line-table-catalog-between" style="cursor:row-resize; display:none">
        </div>

        <div id="divCatView" ref="catalog" class="c-flex-table" @click="onCatClick();" style="text-align: left; width:100%; height:300px; display: none;"> 

        <div v-for="n in 7" class="c-flex-table__column" :key="n">
          <div v-if="n == 1" class="c-flex-table__header_cat" style="min-width:200px;" :class="{'is-pass': !isCatalog()}">Артикул<br/>{{ filter }}</div>
          <div v-if="n == 2" class="c-flex-table__header_cat">
            <td v-if="mode=='tree'" style="min-width: 30px;">
              <img @click="onTreeViewChange();" v-if="!tree_on" src="../assets/TREE.png" height="30"><img @click="onTreeViewChange();" v-if="tree_on" src="../assets/TREE2.png" height="30">
            </td>
            <td v-else style="min-width: 30px;"></td>
            <td style="min-width: 700px; text-align: center; vertical-align: middle;" :class="{'is-pass': !isCatalog()}">Номенклатура</td>
          </div>
          <div v-if="n == 3" class="c-flex-table__header_cat" style="min-width:50px;" :class="{'is-pass': !isCatalog()}">Колич.</div>
          <div v-if="n == 4" class="c-flex-table__header_cat" style="min-width:100px;" :class="{'is-pass': !isCatalog()}">Цена</div>
          <div v-if="n == 5" class="c-flex-table__header_cat" style="min-width:100px;" :class="{'is-pass': !isCatalog()}">Сумма</div>
          <div v-if="n == 6" class="c-flex-table__header_cat" style="min-width:100px;" :class="{'is-pass': !isCatalog()}">Скидка</div>
          <div v-if="n == 7" class="c-flex-table__header_cat" style="min-width:100px;" :class="{'is-pass': !isCatalog()}">Группа</div>

          <div class="divCatView1">
            <div v-for="(item, index) in catalog" class="c-flex-table__column" :key="index" style="justify-content: left;" @click="onCatalogCellClick(n,index+1);">
              <div v-if="n == 1" class="c-flex-table__data_cat" :cat-cell-col="n" :cat-cell-row="index+1" :class="{'is-pass': !isCatalog()}">{{item['[options1]']}}</div>
              <div v-if="n == 2" class="c-flex-table__data_cat" :cat-cell-col="n" :cat-cell-row="index+1" :class="{'is-pass': !isCatalog()}" style="justify-content: left;">{{item['[constrtype]']}}</div> <!--   -->
              <div v-if="n == 3" class="c-flex-table__data_cat" :cat-cell-col="n" :cat-cell-row="index+1" :class="{'is-pass': !isCatalog()}">{{item['[num]']}}</div>
              <div v-if="n == 4" class="c-flex-table__data_cat" :cat-cell-col="n" :cat-cell-row="index+1" :class="{'is-pass': !isCatalog()}">{{item['[price]']}}</div>
              <div v-if="n == 5" class="c-flex-table__data_cat" :cat-cell-col="n" :cat-cell-row="index+1" :class="{'is-pass': !isCatalog()}">{{item['[sum]']}}</div>
              <div v-if="n == 6" class="c-flex-table__data_cat" :cat-cell-col="n" :cat-cell-row="index+1" :class="{'is-pass': !isCatalog()}">{{item['[guillotine]']}}</div>
              <div v-if="n == 7" class="c-flex-table__data_cat" :cat-cell-col="n" :cat-cell-row="index+1" :class="{'is-pass': !isCatalog()}">{{item['[comment]']}}</div>
            </div>
          </div>

          <div class="divCatView2" style="display: none;">
            <div v-for="(item, index) in tree" class="c-flex-table__column" :key="index" @click="onTreeCellClick(n,index+1);"> <!-- :record_data="record" :data-row="index"  -->
              <div v-if="item.price == undefined">
                <div v-if="n == 1" class="c-flex-table__data_cat is-hid" :tree-cell-col="n" :tree-cell-row="index+1" style="justify-content: left;"></div>
                <div v-if="n == 2" class="c-flex-table__data_cat is-hid" :tree-cell-col="n" :tree-cell-row="index+1" style="justify-content: left;" :style="{'padding-left': item.level*10+'px'}">{{item.name}}</div>
                <div v-if="n == 3" class="c-flex-table__data_cat is-hid" :tree-cell-col="n" :tree-cell-row="index+1"></div>
                <div v-if="n == 4" class="c-flex-table__data_cat is-hid" :tree-cell-col="n" :tree-cell-row="index+1"></div>
                <div v-if="n == 5" class="c-flex-table__data_cat is-hid" :tree-cell-col="n" :tree-cell-row="index+1"></div>
                <div v-if="n == 6" class="c-flex-table__data_cat is-hid" :tree-cell-col="n" :tree-cell-row="index+1"></div>
                <div v-if="n == 7" class="c-flex-table__data_cat is-hid" :tree-cell-col="n" :tree-cell-row="index+1"></div>
              </div>
              <div v-else>
                <div v-if="n == 1" class="c-flex-table__data_cat" :tree-cell-col="n" :tree-cell-row="index+1" style="justify-content: left;">{{item.bigcode}}</div>
                <div v-if="n == 2" class="c-flex-table__data_cat" :tree-cell-col="n" :tree-cell-row="index+1" style="justify-content: left;" :style="{'padding-left': item.level*10+'px'}">{{item.name}}</div>
                <div v-if="n == 3" class="c-flex-table__data_cat" :tree-cell-col="n" :tree-cell-row="index+1"></div>
                <div v-if="n == 4" class="c-flex-table__data_cat" :tree-cell-col="n" :tree-cell-row="index+1">{{item.price}}</div>
                <div v-if="n == 5" class="c-flex-table__data_cat" :tree-cell-col="n" :tree-cell-row="index+1"></div>
                <div v-if="n == 6" class="c-flex-table__data_cat" :tree-cell-col="n" :tree-cell-row="index+1">{{item.discount}}</div>
                <div v-if="n == 7" class="c-flex-table__data_cat" :tree-cell-col="n" :tree-cell-row="index+1">{{item.group}}</div>
              </div>
            </div>
          </div>

        </div>
        </div>

        <div class="c-spec-table" id="c-spec-table" style="height: 150px; display:none;">
        </div>

        <h4><tr>
          <input type="checkbox" @click="onSpecViewClick();" id="SpecView" name="SpecView">
          <td style="width: 130px; text-align: start;" for="SpecView"><div v-if="langRUS">Спецификации</div><div v-if="langENG">Specifications</div><div v-if="langIT">Specifiche</div></td>
          <input type="checkbox" @click="onImgViewClick();" id="ImgView" name="ImgView">
          <td style="width: 130px; text-align: start;" for="ImgView"><div v-if="langRUS">Изображения</div><div v-if="langENG">Images</div><div v-if="langIT">Immagini</div></td>
          <input type="checkbox" @click="onDrawViewClick();" id="DrawView" name="DrawView">
          <td style="width: 90px; text-align: start;" for="DrawView"><div v-if="langRUS">Чертеж</div><div v-if="langENG">Drawing</div><div v-if="langIT">Disegno</div></td>
          <input type="checkbox" @click="onMenuViewClick();" id="MenuView" name="MenuView">
          <td style="width: 130px; text-align: start;" for="MenuView"><div v-if="langRUS">Строка меню</div><div v-if="langENG">Menu bar</div><div v-if="langIT">Barra dei menu</div></td>
          <input type="checkbox" @click="onAccViewClick();" id="AccView" name="AccView">
          <td style="width: 130px; text-align: start;" for="AccView"><div v-if="langRUS">Данные счета</div><div v-if="langENG">Account details</div><div v-if="langIT">Dettagli account</div></td>
          <input type="checkbox" @click="onTableViewClick();" id="TableView" name="TableView"> <!-- style="display: none;" -->
          <td style="width: 130px; text-align: start;" for="TableView"><div v-if="langRUS">Быстрый подбор</div><div v-if="langENG">Table</div><div v-if="langIT">Tabolo</div></td>
          <input type="checkbox" @click="onCatViewClick();" id="CatView" name="CatView">
          <td style="width: 130px; text-align: start;" for="CatView"><div v-if="langRUS">Ручной подбор</div><div v-if="langENG">Catalog</div><div v-if="langIT">Catalogare</div></td>
          <td><font size = 1>({{ mode }} {{ showModal_Edit }}) </font></td>
        </tr></h4>
        <!--tr>
          <div>
            mode: {{ mode }} tree.col:{{tree_cursor_pos.col}},tree.row:{{tree_cursor_pos.row}}
          </div>
        </tr-->

      </div>
    </div>

<!--    <Teleport to="body" name="ModalWindow1"> --> <!--window-constr-del-->  
      <modal ref="modal" :show1="showModal_ConstrDel" @close="showModal_ConstrDel=false">
        <template #header><div v-if="langRUS">Предупреждение</div><div v-if="langENG">Warning</div><div v-if="langIT">Avvertimento</div></template>
        <template #body>
          <div v-if="langRUS">Вы действительно хотите удалить конструкцию?<br>Восстановить её будет невозможно.</div>          
          <div v-if="langENG">Are you sure you want to delete the structure?<br>It will be impossible to restore it.</div> <!--${this.cursor_pos.col}-->
          <div v-if="langIT">Sei sicuro di voler eliminare la struttura?<br>Sarà impossibile ripristinarlo.</div> <!--${this.cursor_pos.col}-->
        </template> <!--${this.cursor_pos.col}-->
        <template #footer>
          <button id="button_del" :class="{'modal-default-button':showModal_ConstrDelPos==0,'modal-button':showModal_ConstrDelPos==1}" @click="onConstrDeleteYes();">
            <div v-if="langRUS">Удалить</div><div v-if="langENG">Delete</div><div v-if="langIT">Eliminare</div>            
          </button>
          <button id="button_cancel" :class="{'modal-default-button':showModal_ConstrDelPos==1,'modal-button':showModal_ConstrDelPos==0}" @click="closeModal();">
            <div v-if="langRUS">Отказаться</div><div v-if="langENG">Cancel</div><div v-if="langIT">Rifiutare</div>                        
          </button>
        </template>
      </modal>
<!--    </Teleport> -->
<!--    <Teleport to="body" name="modal-window2"> --> <!--window-account-save-->
      <modal ref="modal" :show1="showModal_AccountSave" @close="showModal_AccountSave=false">
        <template #header><div v-if="langRUS">Инфо</div><div v-if="langENG">Info</div><div v-if="langIT">informazioni</div></template>
        <template #body>
          <div v-if="langRUS">Счет был изменен, сохранить изменения?</div>
          <div v-if="langENG">The account has been changed, do you want to save the changes?</div>
          <div v-if="langIT">L'account è stato modificato, vuoi salvare le modifiche?</div>
        </template>
        <template #footer>
          <button id="button_save" :class="{'modal-default-button':showModal_AccountSavePos==0,'modal-button':showModal_AccountSavePos==1 || showModal_AccountSavePos==2 }" @click="OnProductsSave();">
            <div v-if="langRUS">Сохранить</div><div v-if="langENG">Save</div><div v-if="langIT">Salva</div>
          </button>
          <button id="button_notsave" :class="{'modal-default-button':showModal_AccountSavePos==1,'modal-button':showModal_AccountSavePos==0 || showModal_AccountSavePos==2}" @click="OnProductsNoSave();">
            <div v-if="langRUS">Не сохранять</div><div v-if="langENG">Don't save</div><div v-if="langIT">Non salvare</div>
          </button>
          <button id="button_cancel" :class="{'modal-default-button':showModal_AccountSavePos==2,'modal-button':showModal_AccountSavePos==0 || showModal_AccountSavePos==1}" @click="closeModal();">
            <div v-if="langRUS">Отказаться</div><div v-if="langENG">Cancel</div><div v-if="langIT">Rifiutare</div>
          </button>
        </template>
      </modal>
<!--    </Teleport> -->
<!--    <Teleport to="body" name="ModalWindow1"> --> <!--window-constr-del-->  
      <modal ref="modal" :show2="showModal_AccountSend" @close="showModal_AccountSend=false">
        <template #header><div v-if="langRUS">Предупреждение</div><div v-if="langENG">Warning</div><div v-if="langIT">Avvertimento</div></template>
        <template #body>
          <div v-if="langRUS">Перед передачей счета в КИС он будет пересчитан и записан.<br><br>Передача в КИС может занимать продолжительное время.<br>Дождитесь окончания передачи.<br><br>Передать счет в КИС?</div>
          <div v-if="langENG">Before transferring the invoice to the KIS, it will be recalculated and recorded.<br><br>Transfer the account to CIS?</div> <!--${this.cursor_pos.col}-->
          <div v-if="langIT">Prima di trasferire la fattura al KIS, questa verrà ricalcolata e registrata.<br><br>Trasferire l'account al CIS?</div> <!--${this.cursor_pos.col}-->
        </template> <!--${this.cursor_pos.col}-->
        <template #footer2>
          <button id="button_del" :class="{'modal-default-button':showModal_AccountSendPos==0,'modal-button':showModal_AccountSendPos==1}" @click="OnProductsSend();">
            <div v-if="langRUS">Передать</div><div v-if="langENG">Transfer</div><div v-if="langIT">Devolvere</div>            
          </button>
          <button id="button_cancel" :class="{'modal-default-button':showModal_AccountSendPos==1,'modal-button':showModal_AccountSendPos==0}" @click="closeModal();">
            <div v-if="langRUS">Отказаться</div><div v-if="langENG">Cancel</div><div v-if="langIT">Rifiutare</div>                        
          </button>
        </template>
      </modal>
<!--    </Teleport> -->

      <modal ref="modal" :show3="showModal_AccountPrint" @close="showModal_AccountPrint=false">
        <template #header><div v-if="langRUS">Создание КП</div><div v-if="langENG">Creation of a CE</div><div v-if="langIT">Creazione del CP</div></template>
        <template #body>
          <div style="font-size: large; text-align: center;" v-if="langRUS">Настройки создания коммерческого предложения</div>
          <div style="font-size: large; text-align: center;" v-if="langENG">Commercial proposal creation settings</div>
          <div style="font-size: large; text-align: center;" v-if="langIT">Impostazioni per la creazione di un'offerta commerciale</div>

          <tr style="height: 20px;"></tr>
          <tr><input type="checkbox" id="CP" v-model="print_info.cp" style="margin-left: 100px;"/>
            <td><div style="font-size: large;" v-if="langRUS">Коммерческое предложение</div><div v-if="langENG">Commercial offer</div><div v-if="langIT">Offerta commerciale</div></td></tr>

          <tr><input type="checkbox" id="CPTotal" v-model="print_info.cptotal" style="margin-left: 110px;"/>
            <td><div style="font-size: small;" v-if="langRUS">Выводить ИТОГО</div><div v-if="langENG">Display TOTAL</div><div v-if="langIT">Visualizza TOTALE</div></td></tr>
          <tr><input type="checkbox" id="CPOpen" v-model="print_info.cpopen" style="margin-left: 110px;" title="Не используйте без лишней необходимости! Руководство не одобряет схемы открывания в КП!"/>
            <td><div style="font-size: small;" v-if="langRUS">Выводить схему открывания</div><div v-if="langENG">Display the opening diagram</div><div v-if="langIT">Visualizza lo schema di apertura</div></td></tr>
          <tr style="height: 10px;"></tr>

            <!-- Выводить номинальные размеры Выводить размеры проема Выводить ИТОГО Выводить схему открывания -->
          <tr><input type="checkbox" id="Append" v-model="print_info.append" style="margin-left: 100px;"/>
            <td><div style="font-size: large;" v-if="langRUS">Приложения</div><div v-if="langENG">Additions</div><div v-if="langIT">Aggiunte</div></td></tr>
          <tr><input type="checkbox" id="Tech" v-model="print_info.tech" style="margin-left: 100px;"/>
            <td><div style="font-size: large;" v-if="langRUS">Техническая информация</div><div v-if="langENG">Technical information</div><div v-if="langIT">Informazioni tecniche</div></td></tr>
          <tr style="height: 10px;"></tr>
            <!--tr><input type="checkbox" id="checkbox3" style="margin-left: 100px;" />
            <td><div style="font-size: small;" v-if="langRUS">Редактировать HTML</div><div v-if="langENG">Edit HTML</div><div v-if="langIT">Modifica HTML</div></td></tr>
          <tr style="height: 10px;"></tr-->
        </template>
        <template #footer3>
          <tr><td style="text-align: center; align-content: center;">
          <button id="button_print" :class="{'modal-default-button':showModal_AccountPrintPos==0,'modal-button':showModal_AccountPrintPos==1 || showModal_AccountPrintPos==2}" @click="onProductsPrint();">
            <div v-if="langRUS">Просмотреть</div><div v-if="langENG">View</div><div v-if="langIT">Visualizzazione</div>            
          </button>
          <button id="button_printdownload" :class="{'modal-default-button':showModal_AccountPrintPos==1,'modal-button':showModal_AccountPrintPos==0 || showModal_AccountPrintPos==2}" @click="onProductsPrintDownload();">
            <div v-if="langRUS">Загрузить HTML</div><div v-if="langENG">Download HTML</div><div v-if="langIT">Carica HTML</div>            
          </button>
          <button id="button_cancel" :class="{'modal-default-button':showModal_AccountPrintPos==2,'modal-button':showModal_AccountPrintPos==0 || showModal_AccountPrintPos==1}" @click="closeModal();">
            <div v-if="langRUS">Отказаться</div><div v-if="langENG">Cancel</div><div v-if="langIT">Rifiutare</div>                        
          </button>
        </td></tr>
        </template>
      </modal>




</div>
</template>

<script>
import Modal from './modal'
import {mapActions, mapState} from 'vuex';
//import pdf from 'pdf-creator-node';

export default {
  name: "v-record-form",
  components: {
    Modal,
  },
  data() {
    return {
      mode: "table",
      mode_prev: "table",
      filter: "",
      tree_on: true,
      no_save: false,
      input_lock: false,

      print_info: {
        cp: true,
        cptotal: true,
        cpopen: false,
        append: true,
        tech: true,
      },

      showModal_ConstrDel: false,
      showModal_ConstrDelPos: 1,
      showModal_AccountSave: false,
      showModal_AccountSavePos: 2,
      showModal_AccountSend: false,
      showModal_AccountSendPos: 0,
      showModal_AccountPrint: false,
      showModal_AccountPrintPos: 0,
      showModal_Edit: false,
      showModal_EditInput: undefined,
      isFetching: true,
      initial_products: [],
      initial_catalog: [],
      initial_account: [],
      isRefresh: 0,
      isRefresh2: 0,
      isRefresh3: 0,
      isRefresh4: 0,
      cursor_pos: {
        col: 1,
        row: 1,
        col_min: 1,
        row_min: 1,
        col_max: 1,
        row_max: 1,
        active: true,
        increaseRowFlag: 0,
        last_direct: "",
        increaseCol ( one ) {
          let flag = true;
          while( flag ){
            this.col ++;
            if(this.col >= this.col_max) { this.col = this.col_max; break; }
            if( one ) break;
            const dataCellEl = document.querySelector(`[data-cell-col="${this.col}"][data-cell-row="${this.row}"]`);
            if( dataCellEl == null ) break;
            if (!dataCellEl.classList.contains("is-hid") && !dataCellEl.classList.contains("is-dis") && !dataCellEl.classList.contains("is-serv")) break;
          }
          this.showCursor();
        },
        decreaseCol ( one ) {
          let flag = true;
          while( flag ){
            this.col --;
            if(this.col <= this.col_min) { this.col = this.col_min; break; }
            if( one ) break;
            const dataCellEl = document.querySelector(`[data-cell-col="${this.col}"][data-cell-row="${this.row}"]`);
            if( dataCellEl == null ) break;
            if (!dataCellEl.classList.contains("is-hid") && !dataCellEl.classList.contains("is-dis") && !dataCellEl.classList.contains("is-serv")) break;
          }
          this.showCursor();
        },
        increaseRow () {
          let flag = true;
          while( flag ){
            this.row ++;
            if(this.row >= this.row_max) { this.row = this.row_max; break; }
            const dataCellEl = document.querySelector(`[data-cell-col="${this.col}"][data-cell-row="${this.row}"]`);
            if( dataCellEl == null ) break;
            if (!dataCellEl.classList.contains("is-hid") && !dataCellEl.classList.contains("is-dis") && !dataCellEl.classList.contains("is-serv")) break;
          }
          this.showCursor();
        },
        decreaseRow () {
          let flag = true;
          while( flag ){
            this.row --;
            if(this.row <= this.row_min) { this.row = this.row_min; break; }
            const dataCellEl = document.querySelector(`[data-cell-col="${this.col}"][data-cell-row="${this.row}"]`);
            if( dataCellEl == null ) break;
            if (!dataCellEl.classList.contains("is-hid") && !dataCellEl.classList.contains("is-dis") && !dataCellEl.classList.contains("is-serv")) break;
          }
          this.showCursor();
        },
        FirstCol () {
          this.col = this.col_min;
          this.showCursor();
        },
        LastCol () {
          this.col = this.col_max;
          this.showCursor();
        },
        FirstRow () {
          this.row = this.row_min;
          this.showCursor();
        },
        LastRow () {
          this.row = this.row_max;
          this.showCursor();
        },
        FirstCell () {
          this.col = this.col_min; this.row = this.row_min;
          this.showCursor();
        },
        LastCell () {
          this.col = this.col_max; this.row = this.row_max;
          this.showCursor();
        },
        increasePage () { // (не доделано)
          if(this.row >= this.row_max) this.row = this.row_max;
          else this.row++;
          this.showCursor();
        },
        decreasePage () { // (не доделано)
          if(this.row <= this.row_min) this.row = this.row_min;
          else this.row--;
          this.showCursor();
        },
        cursorPosCheck ( thisobj ) {
          let flag = true;
          //console.log("cursorPosCheck 1");
          while( flag ){
            const dataCellEl = document.querySelector(`[data-cell-col="${this.col}"][data-cell-row="${this.row}"]`); if( dataCellEl == null ) break;
            if ( !dataCellEl.classList.contains("is-hid") && !dataCellEl.classList.contains("is-dis") && !dataCellEl.classList.contains("is-serv")) break;
            //console.log("cursorPosCheck 2");
            if ( this.last_direct == "up" ){
              if( this.row <= 0 ) { this.row = 0; break; }
              this.row --;
            } else if ( this.last_direct == "down" ){
              if( this.row >= this.row_max ) { this.row = this.row_max; break; }
              thisobj.onProductPressArrowDown();              
              //console.log("cursorPosCheck DOWN");
              //this.row ++;
            } else if ( this.last_direct == "left" ){
              this.col --;
            } else if ( this.last_direct == "right" ){
              this.col ++;
            } else {
              break;
            }
          }
        },
        showCursor() {
          this.hideCursor();
          const dataCellEl = document.querySelector(`[data-cell-col="${this.col}"][data-cell-row="${this.row}"]`);
          if (dataCellEl != null) dataCellEl.classList.add("is-active");
          this.active = true;
          //console.log("table showCursor");
        },
        hideCursor() {
          let elements = document.querySelectorAll(".is-active");
          if (elements.length > 0) {
            for (let elem of elements) { elem.classList.remove("is-active"); }
          }
          this.active = false;
        },
      },
      cat_cursor_pos: {
        col: 2,
        row: 1,
        col_min: 1,
        row_min: 1,
        col_max: 6,
        row_max: 1,
        active: false,
        increaseCol () {
          this.col ++;
          if ( this.col >= this.col_max ) this.col = this.col_max;
          this.showCursor();
        },
        decreaseCol () {
          this.col --;
          if ( this.col <= this.col_min ) this.col = this.col_min;
          this.showCursor();
        },
        increaseRow () {
          this.row ++;
          if ( this.row >= this.row_max ) this.row = this.row_max;
          this.showCursor();
        },
        decreaseRow () {
          this.row --;
          if ( this.row <= this.row_min ) this.row = this.row_min;
          this.showCursor();
        },
        FirstCol () {
          this.col = this.col_min;
          this.showCursor();
        },
        LastCol () {
          this.col = this.col_max;
          this.showCursor();
        },
        FirstRow () {
          this.row = this.row_min;
          this.showCursor();
        },
        LastRow () {
          this.row = this.row_max;
          this.showCursor();
        },
        FirstCell () {
          this.col = this.col_min; this.row = this.row_min;
          this.showCursor();
        },
        LastCell () {
          this.col = this.col_max; this.row = this.row_max;
          this.showCursor();
        },
        showCursor() {
          this.hideCursor();
          const dataCellEl = document.querySelector(`[cat-cell-col="${this.col}"][cat-cell-row="${this.row}"]`);
          if (dataCellEl != null){
            dataCellEl.classList.add("is-cat-active");
            //console.log("catalog showCursor",this.col,this.row);
          }
          this.active = true;
        },
        hideCursor() {
          let elements = document.querySelectorAll(".is-cat-active");
          if (elements.length > 0) {
            for (let elem of elements) { elem.classList.remove("is-cat-active"); }
          }
          this.active = false;
          //console.log("catalog hideCursor");
        },

      },
      tree_cursor_pos: {
        col: 1,
        row: 1,
        col_min: 1,
        row_min: 1,
        col_max: 5,
        row_max: 1,
        active: false,
        increaseCol () {
          this.col ++;
          if ( this.col >= this.col_max ) this.col = this.col_max;
          this.showCursor();
        },
        decreaseCol () {
          this.col --;
          if ( this.col <= this.col_min ) this.col = this.col_min;
          this.showCursor();
        },
        increaseRow () {
          this.row ++;
          if ( this.row >= this.row_max ) this.row = this.row_max;
          this.showCursor();
        },
        decreaseRow () {
          this.row --;
          if ( this.row <= this.row_min ) this.row = this.row_min;
          this.showCursor();
        },
        FirstCol () {
          this.col = this.col_min;
          this.showCursor();
        },
        LastCol () {
          this.col = this.col_max;
          this.showCursor();
        },
        FirstRow () {
          this.row = this.row_min;
          this.showCursor();
        },
        LastRow () {
          this.row = this.row_max;
          this.showCursor();
        },
        FirstCell () {
          this.col = this.col_min; this.row = this.row_min;
          this.showCursor();
        },
        LastCell () {
          this.col = this.col_max; this.row = this.row_max;
          this.showCursor();
        },

        showCursor() {
          this.hideCursor();
          const dataCellEl = document.querySelector(`[tree-cell-col="${this.col}"][tree-cell-row="${this.row}"]`);
          if (dataCellEl != null){
            dataCellEl.classList.add("is-tree-active");
            //console.log("tree showCursor",this.col,this.row);
          }
          this.active = true;
        },
        hideCursor() {
          let elements = document.querySelectorAll(".is-tree-active");
          if (elements.length > 0) {
            for (let elem of elements) { elem.classList.remove("is-tree-active"); }
          }
          this.active = false;
          //console.log("tree hideCursor");
        },

      },
      select_cursor: {
        pos: 0,
        min: 0,
        max: 0,
        active: false,
        close: false,
        increasePos() {
          const element = document.querySelector(".is-active2");
          if (element == undefined ) { alert("element == undefined"); return; }
          const selectDropdownOptionEls = element.children;
          let flag = true;
          while( flag ){
            this.pos ++;
            if(this.pos >= this.max) { this.pos = this.max; break; }
            let property_value = selectDropdownOptionEls[this.pos].getAttribute("value");
            if( property_value.indexOf("==") == -1 ) break;
          }
          this.showCursor();
        },
        decreasePos() {
          const element = document.querySelector(".is-active2");
          if (element == undefined ) { alert("element == undefined"); return; }
          const selectDropdownOptionEls = element.children;
          let flag = true;
          while( flag ){
            this.pos --;
            if(this.pos <= this.min) { this.pos = this.min; break; }
            let property_value = selectDropdownOptionEls[this.pos].getAttribute("value");
            if( property_value.indexOf("==") == -1 ) break;
          }
          this.showCursor();
        },
        FirstPos () {
          this.pos = this.min;
          this.showCursor();
        },
        LastPos () {
          this.pos = this.max;
          this.showCursor();
        },
        showCursor() {
          let elements = document.querySelectorAll(".is-focus");
          for (let elem of elements) { elem.classList.remove("is-focus"); }
          let element = document.querySelector(".is-active2");
          if (this.pos!=-1){
            const selectDropdownEl = element.querySelector(`[c-select-dropdown__option_num="${this.pos}"]`);
            selectDropdownEl.classList.add("is-focus");
          }
          //selectDropdownEl.focus();
        },
      },
      sticky_offsets: {
        x_offset: 0,
        y_offset: 0,
        y_cat_offset: -48,
        get x() {
          if(!this.x_offset) this.x_offset = [...document.querySelectorAll(".c-flex-table__column--sticky-horizontal--js")].reduce((offset, column) => offset + -column.offsetWidth, 0);
          return this.x_offset;
        },
        get y() {
          if(!this.y_offset) this.y_offset = -document.querySelector(".c-flex-table__header").offsetHeight;
          return this.y_offset;
        },
        get y_cat() {
          //if(!this.y_cat_offset) this.y_cat_offset = -document.querySelector(".c-flex-table__header2").offsetHeight;
          return this.y_cat_offset;
        }
      },
      nextFunction: null,
      langRUS:true,
      langENG:false,
      langIT:false,
    };
  },
  props: {
    account_info:{
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapState([
        "properties",
        "productsGGG",
        "catalog",
        "tree",
        "lock_keys",
        "total",
        "total_discount",
        "discount_sum",
        //"account_id",
      ]),
      setStyle(){
        return "padding-left: " + 30 + "px;";
      },
  },
  watch: {
    productsGGG(newProducts) {
      this.cursor_pos.col_max = newProducts.length;
      if(this.cursor_pos.col > this.cursor_pos.col_max) this.cursor_pos.col = this.cursor_pos.col_max;
      this.reloadPageByCursor();
    }
  },
  methods: {
    //Асинхронный запрос к серверу через action в сторе
    ...mapActions([
      "FETCH_TREE",
      "FETCH_PROPERTIES",
      "FETCH_PRODUCTS",
      "FETCH_PRODUCT_OPTIONS_BUNCH",
      "FETCH_PRODUCT_OPTIONS_CHANGED",
      "FETCH_PRODUCT_OPTIONS",
      "SET_PRODUCT_PROPERTY",
      "SET_CATALOG_PROPERTY",
      "CALCULATE_PRODUCT",
      "CALCULATE_TOTAL",
      "RECALCULATE_TOTAL",
      //"CLEAR_CHANGE_FLAG",
      "DELETE_PRODUCT",
      "COPY_PRODUCT",
      "COPY_PRODUCT2",
      "COPY_CATALOG",
      "COPY_CATALOG2",
      "MOVE_PRODUCT_LEFT",
      "MOVE_PRODUCT_RIGHT",
      "MOVE_PRODUCT_UP",
      "MOVE_PRODUCT_DOWN",
      "DELETE_CATALOG",
      "DELETE_PROPERTY_VALUE",
      "UPDATE_ACCOUNT_PRODUCTS",
      "PRINT_ACCOUNT_PRODUCTS",
      "ADD_NEW_PRODUCT",
      "ADD_CATALOG",
      "SET_ACCOUNT_INFO",
      "START_ANIMATION",
      "STOP_ANIMATION",
      "SET_PICTURE",
      "SET_DRAW",
      "GET_LANGUAGE",
      "SET_LANGUAGE",
      "SEND_MAIL",
      "SEND_TO_KIS",
      "UPDATE_ACCOUNT",
      "GET_LOCK_STATE",
      "CHANGE_DISCOUNT",
      "LOCK_UNLOCK_ACCOUNT",
    ]),
    forceRerender() {
      this.isRefresh += 1;  
    },
    forceRerender2() {
      this.isRefresh2 += 1;  
    },
    forceRerender3() {
      this.isRefresh3 += 1;  
    },
    forceRerender4() {
      this.isRefresh4 += 1;  
    },
    forceRerender5() {
    },
    reloadPageByCursor() {
      //console.log("reloadPageByCursor");
      this.cursor_pos.col++;
      this.cursor_pos.col--;
    },
    checkLocked(){
      if ( this.account_info.locked == '1' ){ alert("Счет заблокирован."); return false; } else { return true; }
    },

    setDraw(){
      const checkedDrawView = document.getElementById("DrawView");
      if ( checkedDrawView.checked ) {
        this.SET_DRAW({ thisobj:this, col:this.cursor_pos.col /*, row:this.cursor_pos.row, val:""*/ });
      }
    },
    setPicture(){
      const checkedImgView = document.getElementById("ImgView");
      if ( checkedImgView.checked ) {
        this.SET_PICTURE({ thisobj:this, col:this.cursor_pos.col, row:this.cursor_pos.row, val:"" });
      }
    },
    setPictureVal( val ){
      const checkedImgView = document.getElementById("ImgView");
      if ( checkedImgView.checked ) {
        this.SET_PICTURE({ thisobj:this, col:this.cursor_pos.col, row:this.cursor_pos.row, val:val });
      }
    },

    async fetchProductsWithOptions(idaccounts) {
      let thisobj = this;
      await this.FETCH_PRODUCTS( {/*thisobj,*/ idaccounts} );
      //this.CALCULATE_TOTAL();
      if(this.productsGGG.length) {
        let products_filtered = this.productsGGG.map((product) => {
          return Object.fromEntries(Object.entries(product).filter((product_prop_arr) => {
            if(product_prop_arr[1]) return true;
          }));
        });
        await this.FETCH_PRODUCT_OPTIONS_BUNCH( {thisobj:thisobj, products:products_filtered} );
      }
      this.isFetching = false;
    },
    async onProductSelectChange(property_value, property_name, product_index) {
      //let thisobj = this;
      this.SET_PRODUCT_PROPERTY({property_value, property_name, product_index});
      await this.FETCH_PRODUCT_OPTIONS( { thisobj:this, changed_property_name:property_name, product_index:product_index, base_flag:3 } );
      await this.FETCH_PRODUCT_OPTIONS_CHANGED({thisobj:this});

      // добавлено только что
      this.CALCULATE_PRODUCT( this.cursor_pos.col-1 );
      //this.calculateProduct();
      this.onSpecViewFill();
      this.forceRerender();
      this.reloadPageByCursor();
      this.cursor_pos.showCursor();
      this.setPicture();
      this.setDraw();
      this.ShowHideRow();
    },
    async OnAddClick(){
      if ( !this.checkLocked() ) return;
      switch( this.mode ){
        case 'table': this.onProductAdd(); break;
        case 'catalog': this.onCatalogAdd(); break;
      }
    },
    async onProductAdd() {
      if ( !this.checkLocked() ) return;
      if(this.select_cursor.active) return;

      //this.isFetching = true;
      this.ADD_NEW_PRODUCT();

      let thisobj = this;
      //const elSpecView = document.getElementById("SpecView");
      await this.FETCH_PRODUCT_OPTIONS( { thisobj:thisobj, changed_property_name:"", product_index:this.productsGGG.length - 1, base_flag:3 } );

      //ИЗМЕНЕНИЕ ПОЛОЖЕНИЯ КУРСОРА ПРИ ДОБАВЛЕНИИ НОВОГО ПРОДУКТА (ПЕРЕВОД КУРСОРА НА ПОСЛЕДНИЙ СТОЛБЕЦ)
      this.cursor_pos.LastCol();
      //this.cursor_pos.showCursor(); !
      //this.reloadPageByCursor();
      this.forceRerender3();
      this.forceRerender2();
      this.forceRerender();

      this.$nextTick(function() {
        const dataCellEl = this.$el.querySelector(`[data-cell-col="${this.cursor_pos.col}"][data-cell-row="${this.cursor_pos.row}"]`);
        if ( dataCellEl != null ){
          const scrollX = -(this.$refs.table.getBoundingClientRect().right - dataCellEl.getBoundingClientRect().right) + 20;
          if(scrollX > 0) this.$refs.table.scrollBy(scrollX, 0);
        }
        this.cursor_pos.FirstRow();
        this.cursor_pos.increaseRow();
        //this.cursor_pos.increaseRow(); // убрано недавно, чтобюы курсо не перескакивал на вторую свободную строку
        //this.cursor_pos.increaseRowFlag = 1;
        this.cursor_pos.showCursor();
        this.setPicture();
        this.setDraw();
        this.ShowHideRow();
        //this.cursor_pos.onProductPressArrowDown();
      });

    },

    onCatalogAdd(){
      if ( !this.checkLocked() ) return;
      if ( this.modeSet("tree") ) { this.cursor_pos.hideCursor(); this.cat_cursor_pos.hideCursor(); this.tree_cursor_pos.showCursor(); }
    },

    onTreeViewChange(){
      //this.tree_on = !this.tree_on;
      if (this.mode == 'tree'){
        this.FETCH_TREE( { thisobj:this, mode:this.tree_on, num_node:this.tree[this.tree_cursor_pos.row-1].parentcode, filter:this.filter } );
      }
    },

    onCatClick(){
      if ( this.showModal_Edit == true ) return;
      if ( this.mode == 'tree' ) return;
      this.modeSet("catalog","onCatClick");
    },

    onTableClick(){
      if ( this.showModal_Edit == true ) return;
      this.modeSet("table","onTableClick");
    },

    onDeleteClick() {
      if ( !this.checkLocked() ) return;
      //const answer = window.confirm(`Вы уверены, что хотите удалить продукт № ${this.cursor_pos.col} ?`);
      switch( this.mode ){
        case 'table':
          if ( this.productsGGG[this.cursor_pos.col-1].isGroup ){
            if ( this.cursor_pos.col < this.cursor_pos.col_max ) {
              if ( this.productsGGG[this.cursor_pos.col-1+1].isSubGroup || this.productsGGG[this.cursor_pos.col-1+1].isEndGroup ){
                alert( "Удаление группы возможно только после удаления всех ее элементов." );
                return;
              }
            }
          }
          this.showModal_ConstrDel = true;
          break;
        case 'catalog':
          this.onCatalogDelete();
          break;
      }
    },
    async onProductSpecification() {
    },
    async onProductPropertyValueDelete() {
      if ( !this.checkLocked() ) return;
      const dataCellEl = document.querySelector(`[data-cell-col="${this.cursor_pos.col}"][data-cell-row="${this.cursor_pos.row}"]`);
      if (dataCellEl == undefined ) alert("dataCellEl == undefined");
      if (dataCellEl.classList.contains("is-hid") || dataCellEl.classList.contains("is-dis") || dataCellEl.classList.contains("is-serv") ) return;

      //---------- запрет удаления не пустой группы конструкций
      if ( this.productsGGG[this.cursor_pos.col-1].isGroup && this.properties.key[this.cursor_pos.row - 1] == '[constr]' ){
        if ( this.cursor_pos.col < this.cursor_pos.col_max ) {
          if ( this.productsGGG[this.cursor_pos.col-1+1].isSubGroup || this.productsGGG[this.cursor_pos.col-1+1].isEndGroup ){
            alert( "Удаление конструкции группы возможно только после удаления всех ее элементов." );
            return;
          }
        }
      }

      const property_name = this.properties.key[this.cursor_pos.row - 1];
      this.DELETE_PROPERTY_VALUE({product_index: this.cursor_pos.col - 1, property_name});
      //this.isFetching = true;
      let thisobj = this;
      //const elSpecView = document.getElementById("SpecView");
      await this.FETCH_PRODUCT_OPTIONS( { thisobj:thisobj, changed_property_name:property_name, product_index:this.cursor_pos.col - 1, base_flag:3 });
      //alert("onProductPropertyValueDelete");
      this.CALCULATE_PRODUCT( this.cursor_pos.col-1 );
      //this.calculateProduct();
      this.onSpecViewFill();
      this.forceRerender();
      this.reloadPageByCursor();
      this.cursor_pos.showCursor();
      this.ShowHideRow();
      this.forceRerender();
      //this.isFetching = false;
    },

    async onCatalogPropertyValueDelete() {
      if ( !this.checkLocked() ) return;
      const dataCellEl = document.querySelector(`[data-cell-col="${this.cursor_pos.col}"][data-cell-row="${this.cursor_pos.row}"]`);
      if (dataCellEl == undefined ) alert("dataCellEl == undefined");
      if (dataCellEl.classList.contains("is-hid") || dataCellEl.classList.contains("is-dis") || dataCellEl.classList.contains("is-serv") ) return;

      //---------- запрет удаления не пустой группы конструкций
      if ( this.productsGGG[this.cursor_pos.col-1].isGroup && this.properties.key[this.cursor_pos.row - 1] == '[constr]' ){
        if ( this.cursor_pos.col < this.cursor_pos.col_max ) {
          if ( this.productsGGG[this.cursor_pos.col-1+1].isSubGroup || this.productsGGG[this.cursor_pos.col-1+1].isEndGroup ){
            alert( "Удаление конструкции группы возможно только после удаления всех ее элементов." );
            return;
          }
        }
      }

      const property_name = this.properties.key[this.cursor_pos.row - 1];
      this.DELETE_PROPERTY_VALUE({product_index: this.cursor_pos.col - 1, property_name});
      //this.isFetching = true;
      let thisobj = this;
      //const elSpecView = document.getElementById("SpecView");
      await this.FETCH_PRODUCT_OPTIONS( { thisobj:thisobj, changed_property_name:property_name, product_index:this.cursor_pos.col - 1, base_flag:3 });
      //alert("onProductPropertyValueDelete");
      this.CALCULATE_PRODUCT( this.cursor_pos.col-1 );
      //this.calculateProduct();
      this.onSpecViewFill();
      this.forceRerender();
      this.reloadPageByCursor();
      this.cursor_pos.showCursor();
      this.ShowHideRow();
      this.forceRerender();
      //this.isFetching = false;
    },

    async onProductPropertyValueChange() {
      if ( !this.checkLocked() ) return;
      const dataCellEl = document.querySelector(`[data-cell-col="${this.cursor_pos.col}"][data-cell-row="${this.cursor_pos.row}"]`);
      if (dataCellEl == undefined ) alert("dataCellEl == undefined");
      if (dataCellEl.classList.contains("is-hid") || dataCellEl.classList.contains("is-dis") || dataCellEl.classList.contains("is-serv") ) return;

      const property_name = this.properties.key[this.cursor_pos.row - 1];
      //this.DELETE_PROPERTY_VALUE({product_index: this.cursor_pos.col - 1, property_name});
      //this.isFetching = true;
      //let thisobj = this;
      await this.FETCH_PRODUCT_OPTIONS( { thisobj:this, changed_property_name:property_name, product_index:this.cursor_pos.col - 1, base_flag:3 });
      
      //this.$nextTick(function() {
        //this.forceRerender();
        //this.reloadPageByCursor();
        //this.cursor_pos.showCursor();
        //this.ShowHideRow();
        //this.forceRerender();
        //this.reloadPageByCursor();
      //});
      this.forceRerender();

      //this.isFetching = false;
    },


    onCopyClick( e ) {
      if( !this.checkLocked() ) return;
      switch( this.mode ){
        case 'table':   this.onProductCopy( e ); break;
        case 'catalog': this.onCatalogCopy( e ); break;
      }
    },


    onProductCopy( e ) {
      if( !this.checkLocked() ) return;
      if( this.lock_keys ) { e.stopImmediatePropagation(); console.log("Blocked speed mouse input..."); return; }
      if( this.select_cursor.active ) return;

      if ( !e.shiftKey ){
        this.COPY_PRODUCT(this.cursor_pos.col - 1);
        this.cursor_pos.increaseCol( true );
      } else {
        this.COPY_PRODUCT2(this.cursor_pos.col - 1);
        this.cursor_pos.col_max ++;
        this.cursor_pos.LastCol();
      }

      this.reloadPageByCursor();
      //this.calculateResultSumm()

      this.$nextTick(function(){
        const dataCellEl = this.$el.querySelector(`[data-cell-col="${this.cursor_pos.col}"][data-cell-row="${this.cursor_pos.row}"]`);
        const scrollX = -(this.$refs.table.getBoundingClientRect().right - dataCellEl.getBoundingClientRect().right) + 20;
        if(scrollX > 0) this.$refs.table.scrollBy(scrollX, 0);
      });
    },

    async onProductRecalc() {
      if ( !this.checkLocked() ) return;
      this.START_ANIMATION();
      await this.RECALCULATE_TOTAL({thisobj:this});
      this.STOP_ANIMATION();

      this.forceRerender3();
      alert("Счет пересчитан.");
      //this.forceRerender4();

    },
    onProductMoveLeft(e) {
      if ( !this.checkLocked() ) return;
      if ( this.lock_keys ) { e.stopImmediatePropagation(); console.log("Blocked speed mouse input..."); return; }
      if ( this.productsGGG[this.cursor_pos.col-1].isGroup ){ alert( "Перемещение группы пока не реализовано.\nПеремещайте элементы группы." ); return; }
      if( this.cursor_pos.col > this.cursor_pos.col_min ) {
        this.MOVE_PRODUCT_LEFT(this.cursor_pos.col - 1);
        this.cursor_pos.decreaseCol( true );
        this.FETCH_PRODUCT_OPTIONS_CHANGED({thisobj:this});
      }
    },
    onProductMoveRight(e) {
      if ( !this.checkLocked() ) return;
      if ( this.lock_keys ) { e.stopImmediatePropagation(); console.log("Blocked speed mouse input..."); return; }
      if ( this.productsGGG[this.cursor_pos.col-1].isGroup ){ alert( "Перемещение группы пока не реализовано.\nПеремещайте элементы группы." ); return; }
      if( this.cursor_pos.col < this.cursor_pos.col_max ) {
        this.MOVE_PRODUCT_RIGHT(this.cursor_pos.col - 1);
        this.cursor_pos.increaseCol( true );
        this.FETCH_PRODUCT_OPTIONS_CHANGED({thisobj:this});
      }
    },

    onProductMoveUp(e){
      if ( !this.checkLocked() ) return;
      if ( this.lock_keys ) { e.stopImmediatePropagation(); console.log("Blocked speed mouse input..."); return; }
      if( this.cat_cursor_pos.row > this.cat_cursor_pos.row_min ) {
        this.MOVE_PRODUCT_UP(this.cat_cursor_pos.row - 1);
        this.cat_cursor_pos.decreaseRow( true );
      }
    },
    onProductMoveDown(e){
      if ( !this.checkLocked() ) return;
      if ( this.lock_keys ) { e.stopImmediatePropagation(); console.log("Blocked speed mouse input..."); return; }
      if( this.cat_cursor_pos.row < this.cat_cursor_pos.row_max ) {
        this.MOVE_PRODUCT_DOWN(this.cat_cursor_pos.row - 1);
        this.cat_cursor_pos.increaseRow( true );
      }
    },
    onCatalogDelete(e){
      if ( !this.checkLocked() ) return;
      if ( this.lock_keys ) { e.stopImmediatePropagation(); console.log("Blocked speed mouse input..."); return; }
      this.DELETE_CATALOG( this.cat_cursor_pos.row - 1 );
      this.cat_cursor_pos.row_max = this.catalog.length;
      if ( this.cat_cursor_pos.row > this.cat_cursor_pos.row_max ){
        this.cat_cursor_pos.row = this.cat_cursor_pos.row_max;
      }
      this.cat_cursor_pos.showCursor();
    },

    onCatalogCopy( e ){
      if ( !this.checkLocked() ) return;
      if ( this.lock_keys ) { e.stopImmediatePropagation(); console.log("Blocked speed mouse input..."); return; }

      if ( !e.shiftKey ){
        this.COPY_CATALOG( this.cat_cursor_pos.row - 1 );
        this.cat_cursor_pos.row_max = this.catalog.length;
        this.cat_cursor_pos.increaseRow();
        this.cat_cursor_pos.showCursor();
      } else {
        this.COPY_CATALOG2( this.cat_cursor_pos.row - 1 );
        this.cat_cursor_pos.row_max = this.catalog.length;
        this.cat_cursor_pos.LastRow();
        this.cat_cursor_pos.showCursor();
      }

    },


    async onAccountProductsUpdate(mode) { //
      if ( !this.checkLocked() ) return;
      this.saveAccountSettings();
      let curr_account = [{ account_info:this._props.account_info }];
      if(!isEqualProductsArray(this.productsGGG, this.initial_products) || !isEqualProductsArray(this.catalog, this.initial_catalog) || !isEqualProductsArray(curr_account, this.initial_account)) {
        this.account_info.factory = "Union";
        //this.account_info.date1 = this.DateStringToString( this.account_info.date1 );
        //this.account_info.total = this.total;
        //this.account_info.total_discount = this.total_discount;
        //this.account_info.discount = this.discount;
        //this.account_info.discount_sum = this.discount_sum;
        await this.UPDATE_ACCOUNT_PRODUCTS({ account_info:this.account_info });
        this.setInitialProducts();
        if ( mode ) alert("Счет сохранен.");
      } else {
        //alert("Счет не изменялся, сохранение не требуется.");
      }
    },

    async onAccountProductsPrint() {
      this.showModal_AccountPrint = true;
      this.modeSet("accountPrint");
    },

    async onProductsPrint(){
      if ( this.account_info.kp == "" ){ alert( "Заполните номер КП." ); return; }
      this.save_print_info();
      this.closeModal();
      this.START_ANIMATION();
      await this.PRINT_ACCOUNT_PRODUCTS({ account_info:this.account_info, mode:0 });
    },
    async onProductsPrintDownload(){
      alert("Загрузка исходного текста КП в разработке.");
      /*if ( this.account_info.kp == "" ){ alert( "Заполните номер КП." ); return; }
      this.save_print_info();
      this.closeModal();
      this.START_ANIMATION();
      await this.PRINT_ACCOUNT_PRODUCTS({ account_info:this.account_info, mode:1 });*/
    },

    async onAccountProductsSend(){
      if ( isEmpty( this.account_info.deal ) ) { alert("Номер сделки должен быть заполнен."); return; }
      if ( isNaN( this.account_info.deal ) ) { alert("Номер сделки должен быть числом."); return; }
      if ( isEmpty( this.account_info.account ) && !isEmpty( this.account_info.client_id ) ) { alert("Если заполнен ID контрагента,\nномер счета тоже должен быть заполнен."); return; }
      if ( !isEmpty( this.account_info.account ) && isEmpty( this.account_info.client_id ) ) { alert("Если заполнен номер счета,\nID контрагента тоже должен быть заполнен."); return; }
      if ( isNaN( this.account_info.account ) ) { alert("Номер счета должен быть числом."); return; }
      if ( isNaN( this.account_info.client_id ) ) { alert("ID контрагента должен быть числом."); return; }
      if ( !isEmpty( this.account_info.client_id ) && this.account_info.client_id.length != 9 ) { alert("Длина ID контрагента должен быть равна 9,\nс ведущими нулями вначале: '000012345'."); return; }

      this.showModal_AccountSend = true;
      this.modeSet("accountSend");
    },

    AccountSetLock(){
      if ( this.account_info.locked == '1' ) {
        document.getElementById("iddeal").setAttribute('readonly', true);
        document.getElementById("iddate1").setAttribute('readonly', true);
        document.getElementById("idcustomer").setAttribute('readonly', true);
        document.getElementById("idcomment").setAttribute('readonly', true);
        document.getElementById("iddiscount").setAttribute('readonly', true);
        document.getElementById("idkp").setAttribute('readonly', true);
        document.getElementById("idaccount").setAttribute('readonly', true);
        document.getElementById("idclient_id").setAttribute('readonly', true);
      } else {
        document.getElementById("iddeal").removeAttribute('readonly');
        document.getElementById("iddate1").removeAttribute('readonly');
        document.getElementById("idcustomer").removeAttribute('readonly');
        document.getElementById("idcomment").removeAttribute('readonly');
        document.getElementById("iddiscount").removeAttribute('readonly');
        document.getElementById("idkp").removeAttribute('readonly');
        document.getElementById("idaccount").removeAttribute('readonly');
        document.getElementById("idclient_id").removeAttribute('readonly');
      }
      //this.forceRerender5();
    },

    onExit() {
        //this.$router.push("/records");
        this.$router.push({
              name: 'records',
              params: {
                'account_id': this.account_info.account_id, 
                //'account': this.record_data.account,
                //'customer': this.record_data.customer,
              },
            });
    },
    setInitialProducts() {
      if (this.productsGGG.length) this.initial_products = JSON.parse(JSON.stringify(this.productsGGG));
      if (this.catalog.length) this.initial_catalog = JSON.parse(JSON.stringify(this.catalog));
      //this.initial_account = [{account:this._props.account_info.account, account_id:this._props.account_info.account_id, customer:this._props.account_info.customer}];
      this.initial_account = [{
        account_id: this._props.account_info.account_id,
        date1: this._props.account_info.date1,
        deal: this._props.account_info.deal,
        account: this._props.account_info.account,
        kp: this._props.account_info.kp,
        customer: this._props.account_info.customer,
        factory: this._props.account_info.factory,
        discount: this._props.account_info.discount,
        discount_sum: this._props.account_info.discount_sum,        
        total: this._props.account_info.total,
        total_discount: this._props.account_info.total_discount,
        comment: this._props.account_info.comment,
        extendinfo: this._props.account_info.extendinfo,
        printinfo: this._props.account_info.printinfo,
        client_id: this._props.account_info.client_id,
        locked: this._props.account_info.locked,
      }];

    },
    isCursorOnCell (col, row) {
      if(col == this.cursor_pos.col && row == this.cursor_pos.row) return true;
    },
    isCursorOnCatCell (col, row) {
      if(col == this.cat_cursor_pos.col && row == this.cat_cursor_pos.row) return true;
    },
    /*isRefresh () {
      return this.isRefreshFlag;
    },*/
    setCursorPos(col, row) {
      this.cursor_pos.col = col;
      this.cursor_pos.row = row;
    },
    setCatalogCursorPos(col, row) {
      this.cat_cursor_pos.col = col;
      this.cat_cursor_pos.row = row;
    },
    setTreeCursorPos(col, row) {
      this.tree_cursor_pos.col = col;
      this.tree_cursor_pos.row = row;
    },

    onProductPressEnter(){
      if ( !this.checkLocked() ) return;

      //---------- запрет удаления не пустой группы конструкций
      if ( this.productsGGG[this.cursor_pos.col-1].isGroup && this.properties.key[this.cursor_pos.row - 1] == '[constr]' ){
        if ( this.cursor_pos.col < this.cursor_pos.col_max ) {
          if ( this.productsGGG[this.cursor_pos.col-1+1].isSubGroup || this.productsGGG[this.cursor_pos.col-1+1].isEndGroup ){
            alert( "Изменение конструкции группы возможно только после удаления всех ее элементов." );
            return;
          }
        }
      }

      //const dataCellEl = this.$el.querySelector(`[data-cell-col="${this.cursor_pos.col}"][data-cell-row="${this.cursor_pos.row}"]`);
      const dataCellEl = document.querySelector(`[data-cell-col="${this.cursor_pos.col}"][data-cell-row="${this.cursor_pos.row}"]`);
      if (dataCellEl == undefined ) alert("dataCellEl == undefined");
      if (dataCellEl.classList.contains("is-hid") || dataCellEl.classList.contains("is-dis") || dataCellEl.classList.contains("is-serv") ) return;

      const selectDropdownEl = dataCellEl.querySelector(".c-flex-table__select-dropdown");
      if (selectDropdownEl == undefined ) alert("selectDropdownEl == undefined");
      const selectDropdownOptionEls = selectDropdownEl.children;

      this.select_cursor.max = selectDropdownOptionEls.length - 1;
      this.select_cursor.active = true;
      this.select_cursor.pos = selectDropdownEl.dataset.currentOptionPos;



      
      let property_value = "";
      if ( this.select_cursor.pos != -1 ){
        property_value = selectDropdownOptionEls[this.select_cursor.pos].getAttribute("value");
        if( property_value.indexOf("==") != -1 ) this.select_cursor.pos++;
      } else {
        property_value = dataCellEl.innerText; // НОВОЕ, проверить!
      }










      selectDropdownEl.classList.add("is-active2");
      selectDropdownEl.removeAttribute("style");

      const bottomFreeSpace = this.$refs.table.getBoundingClientRect().bottom - dataCellEl.getBoundingClientRect().bottom - 25;
      const topFreeSpace = dataCellEl.getBoundingClientRect().top - this.$refs.table.getBoundingClientRect().top - this.$refs["table-header"][0].offsetHeight - 62;
      selectDropdownEl.style.height = "max-content";
      selectDropdownEl.style.minWidth = `${dataCellEl.getBoundingClientRect().width}px`; //dataCellEl.style.width;
      selectDropdownEl.style.width = "max-content";

      const bottomFreeSpaceAfterOpen = bottomFreeSpace - selectDropdownEl.offsetHeight;
      const topFreeSpaceAfterOpen = topFreeSpace - selectDropdownEl.offsetHeight;
      const dataCellHeight = dataCellEl.offsetHeight;
      let isDropUp = false;

      if(bottomFreeSpaceAfterOpen <= 0 && bottomFreeSpaceAfterOpen < topFreeSpaceAfterOpen) isDropUp = true;
      if (topFreeSpaceAfterOpen > 0 || bottomFreeSpaceAfterOpen > 0) {
        if(isDropUp) {
          selectDropdownEl.style.bottom = `${dataCellHeight}px`;
          selectDropdownEl.style.top = "unset";
        }
      } else {
        selectDropdownEl.style.height = `${isDropUp ? topFreeSpace : bottomFreeSpace }px`;
        selectDropdownEl.style.overflowY = "scroll";
        if(isDropUp) {
          selectDropdownEl.style.bottom = `${dataCellHeight}px`;
          selectDropdownEl.style.top = "unset";
        }
      }

      const rightFreeSpace = this.$refs.table.getBoundingClientRect().right - selectDropdownEl.getBoundingClientRect().right;
      if (rightFreeSpace < 0){
        selectDropdownEl.style.left = `${rightFreeSpace}px`;
      }

      //alert(selectDropdownOptionEls[0].getAttribute("value"));
      //let max_width=Math.max(...selectDropdownOptionEls.getAttribute("value"));
      //selectDropdownEl.style.width ="300px"; //measureText
      //const canvas = document.getElementById('canvas');
      //const ctx = canvas.getContext('2d');
      //let text = ctx.measureText('Hello world');
      //console.log(text.width);  // 56;

      const curSelectDrodpownOptionEl = selectDropdownEl.children[this.select_cursor.pos];
      if ( curSelectDrodpownOptionEl!= undefined ){
        const scrollY = -selectDropdownEl.getBoundingClientRect().bottom + curSelectDrodpownOptionEl.getBoundingClientRect().bottom;
        if(scrollY > 0) selectDropdownEl.scrollBy(0, scrollY + selectDropdownEl.style.height.replace("px","")/2);
        this.setPictureVal( curSelectDrodpownOptionEl.innerText );
      }
      this.select_cursor.showCursor();

      //---------- проверить на редактируемое поле
      const property_name = selectDropdownEl.dataset.propertyName;
      if ( isPropertiesEditable( this,property_name ) ){

        //---------- удалить поле ввода, если оно есть
        //const num_input = document.getElementById( "NumericInput" ); if ( num_input != undefined ) num_input.parentNode.removeChild( num_input );
        //const num_inputfset = document.getElementById( "NumericInputFSet" ); if ( num_inputfset != undefined ) num_inputfset.parentNode.removeChild( num_inputfset );
        this.NumericInputRemove(); this.NumericInputFSetRemove();

        //let num_input_ok = document.getElementById( "NumericInputOK" ); if ( num_input_ok != undefined ) num_input_ok.parentNode.removeChild( num_input_ok );
        //let num_input_cancel = document.getElementById( "NumericInputCancel" ); if ( num_input_cancel != undefined ) num_input_cancel.parentNode.removeChild( num_input_cancel );

        //---------- создать редактируемое поле ввода
        this.showModal_EditInput = document.createElement("input");
        this.showModal_EditInput.type = "text";
        this.showModal_EditInput.id = "NumericInput";
        this.showModal_EditInput.className = "c-flex-table__data";
        //this.showModal_EditInput.title="City where you were born."
        //this.showModal_EditInput.value = document.title;
        //this.showModal_EditInput.style = dataCellEl.style;
        //this.showModal_EditInput.style.left = dataCellEl.getBoundingClientRect().left+"px";
        //this.showModal_EditInput.style.right = dataCellEl.getBoundingClientRect().right+"px";
        this.showModal_EditInput.style.left = "0px";
        this.showModal_EditInput.style.top = "0px";
        this.showModal_EditInput.style.width = dataCellEl.getBoundingClientRect().width-25+"px";
        this.showModal_EditInput.style.height = dataCellEl.getBoundingClientRect().height-7+"px";
        this.showModal_EditInput.style.position = "absolute";
        this.showModal_EditInput.style.fontSize = "20px";
        this.showModal_EditInput.style.paddingLeft = "10px";
        this.showModal_EditInput.style.paddingRight = "10px";
        this.showModal_EditInput.style.border = "0";
        this.showModal_EditInput.style.outline = "0";

        //---------- заполнить пле ввода значением свойства
        let str_val = dataCellEl.firstChild.textContent; //curSelectDrodpownOptionEl.innerText;
        if ( str_val!= undefined ){
          str_val = str_val.substring(1,str_val.length-1);
          this.showModal_EditInput.value = str_val;
        }
        this.showModal_Edit = true;

        //---------- создать кнопки OK и Cancel
        /*num_input_ok = document.createElement("button");
        num_input_ok.id = "NumericInputOK";
        num_input_ok.value = "OK";
        num_input_ok.className = "c-flex-table__data";
        num_input_ok.left = "10px";
        num_input_ok.top = "50px";

        num_input_cancel = document.createElement("button");
        num_input_cancel.id = "NumericInputCancel";
        num_input_cancel.value = "Cancel";
        num_input_cancel.className = "c-flex-table__data";
        num_input_cancel.left = "50px";
        num_input_cancel.top = "50px";*/

        //---------- создать рамку вокруг поля ввода
        let fset = undefined, fsetlg = undefined;
        let prod_info = this.productsGGG[this.cursor_pos.col-1].options['[INFO]'];
        if ( prod_info != undefined ) {

          let fsetlgstr = "";
          let vmin = property_name.replace("]","") + "_min]";
          let vmax = property_name.replace("]","") + "_max]";
          let vstep = property_name.replace("]","") + "_step]";
          if ( prod_info[vmin] != undefined ){
            fsetlgstr += " от:"+prod_info[vmin];
            this.showModal_EditInput.min = prod_info[vmin];
          }
          if ( prod_info[vmax] != undefined ){
            fsetlgstr += " до:"+prod_info[vmax];
            this.showModal_EditInput.max = prod_info[vmax];
          }
          if ( prod_info[vstep] != undefined ){
            fsetlgstr += " шаг:"+prod_info[vstep];
            this.showModal_EditInput.step = prod_info[vstep];
          }

          if ( fsetlgstr != "" ){
            fset = document.createElement("fieldset");
            fset.id = "NumericInputFSet";
            fset.style.left = "-2px";
            fset.style.top = "-10px";
            fset.style.width = dataCellEl.getBoundingClientRect().width-17+"px";
            fset.style.height = dataCellEl.getBoundingClientRect().height-3+"px";
            fset.style.position = "absolute";
            fset.style.zIndex = "5";
            fset.style.fontSize = "12px";
            //fset.font.color = 0xff0000;

            fsetlg = document.createElement ("legend");
            fsetlg.innerHTML = fsetlgstr;
            fsetlg.style.color = "red";
            fset.appendChild( fsetlg );
          }
        }



        if ( fset != undefined ){
          dataCellEl.appendChild( fset );
          fset.appendChild( this.showModal_EditInput );
        } else {
          dataCellEl.appendChild( this.showModal_EditInput );
        }
        //dataCellEl.appendChild( num_input_ok );
        //dataCellEl.appendChild( num_input_cancel );
        this.showModal_EditInput.focus();
        this.showModal_EditInput.select();
      }


    },

    onSelectPressEnter(){
      if ( !this.checkLocked() ) return;
      const dataCellEl = document.querySelector(`[data-cell-col="${this.cursor_pos.col}"][data-cell-row="${this.cursor_pos.row}"]`);
      if (dataCellEl == undefined ) alert("dataCellEl == undefined");
      const selectDropdownEl = dataCellEl.querySelector(".c-flex-table__select-dropdown");
      if (selectDropdownEl == undefined ) alert("selectDropdownEl == undefined");
      const selectDropdownOptionEls = selectDropdownEl.children;
      const product_index = selectDropdownEl.dataset.productIndex;
      const property_name = selectDropdownEl.dataset.propertyName;
      //const prev_property_value = this.productsGGG[product_index][property_name];

      let property_value = "";
      if ( this.select_cursor.pos != -1 ){
        property_value = selectDropdownOptionEls[this.select_cursor.pos].getAttribute("value");
        if( property_value.indexOf("==") != -1 ) return false;
      }

      //---------- взять значение из поля ввода, если оно есть
      const num_input = document.getElementById("NumericInput");
      if ( num_input!= undefined ){
        if ( num_input.value != "" ) property_value = num_input.value;
        // убрать служебные символы. ; и : используются как разделители свойств
        property_value = property_value.replaceAll(";",",").replaceAll(":",".").replaceAll("'"," ");
        //console.log("property_value",property_value);
        if ( isPropertiesNumeric( this,property_name ) ){
          //---------- введенная строка не является числом, проверить, есть ли в списке
          if ( isNaN( property_value ) ) {
            let property_list = this.productsGGG[product_index].options[property_name];
            let ind_temp = property_list.indexOf( property_value );
            if ( ind_temp == -1 ){ return false; }
          //---------- введенная строка - число, проверить, подходит ли оно по интервалам
          } else {
            if ( num_input.min != undefined && num_input.min != "" ){
              if ( Number( property_value ) < Number( num_input.min ) ) return false; // значение меньше минимума
            }
            if ( num_input.max != undefined && num_input.max != "" ){
              if ( Number( property_value ) > Number( num_input.max ) ) return false; // значение больше максимума
            }
            if ( num_input.step != undefined && num_input.step != "" ){
              if ( Number( property_value ) % Number( num_input.step ) != 0 ) return false; // значение не с тем шагом
            }
          }
        }
        //num_input.parentNode.removeChild(num_input);
      }
      this.NumericInputRemove();
      //const num_inputfset = document.getElementById("NumericInputFSet");
      //if ( num_inputfset!= undefined ) num_inputfset.parentNode.removeChild(num_inputfset);
      this.NumericInputFSetRemove();
      //this.showModal_Edit = false;

      //if(prev_property_value != property_value) { // убрал на время разработки для принудительного обновления конструкции
        this.onProductSelectChange(property_value, property_name, product_index);
      //}

      this.select_cursor.pos = this.select_cursor.min;
      this.select_cursor.max = this.select_cursor.min;
      this.select_cursor.active = false;
      selectDropdownEl.classList.remove("is-active2");

      //this.onProductPropertyValueChange();
      this.forceRerender();
      this.ShowHideRow();
      this.cursor_pos.showCursor();
      this.setPicture();
      this.setDraw();
      this.cursor_pos.increaseRowFlag = 2;
      //this.onProductPressArrowDown();
      //this.cursor_pos.last_direct = "down";

      return true;
    },

    onTreePressEnter(){
      if ( !this.checkLocked() ) return;
      //console.log( "tree:", this.tree[this.tree_cursor_pos.row-1].code );
      let node = this.tree[this.tree_cursor_pos.row-1];
      if ( node.price == undefined ){
        this.FETCH_TREE( { thisobj:this, mode:this.tree_on, num_node:node.code, filter:"" } );
      } else {
        this.ADD_CATALOG( { thisobj:this, node:node } );
        //this.cursor_pos.hideCursor(); this.cat_cursor_pos.showCursor();
        this.$nextTick(function(){ this.modeSet("catalog"); });
        this.cat_cursor_pos.LastRow();
      }
    },
    
    onCatalogPressEnter(){
      if ( !this.checkLocked() ) return;
      const dataCellEl = document.querySelector(`[cat-cell-col="${this.cat_cursor_pos.col}"][cat-cell-row="${this.cat_cursor_pos.row}"]`);
      this.showModal_EditInput = document.createElement("input");
      this.showModal_EditInput.type = "text";
      this.showModal_EditInput.id = "NumericInput";
      this.showModal_EditInput.className = "c-flex-table__data";
      this.showModal_EditInput.style.left = "0px";
      this.showModal_EditInput.style.top = "0px";
      this.showModal_EditInput.style.width = dataCellEl.getBoundingClientRect().width-25+"px";
      this.showModal_EditInput.style.height = dataCellEl.getBoundingClientRect().height-7+"px";
      this.showModal_EditInput.style.position = "absolute";
      this.showModal_EditInput.style.fontSize = "20px";
      this.showModal_EditInput.style.paddingLeft = "10px";
      this.showModal_EditInput.style.paddingRight = "10px";
      this.showModal_EditInput.style.border = "0";
      this.showModal_EditInput.style.outline = "0";
      this.showModal_EditInput.style.textAlign="center";
      let str_val = dataCellEl.firstChild.textContent; //curSelectDrodpownOptionEl.innerText;
      if ( str_val!= undefined ){
        //str_val = str_val.substring(1,str_val.length-1);
        this.showModal_EditInput.value = str_val;
      }
      dataCellEl.appendChild( this.showModal_EditInput );
      this.showModal_Edit = true;
      this.showModal_EditInput.focus();
      this.showModal_EditInput.select();
    },

    
    onProductInput(e){
      if ( !this.checkLocked() ) { e.cancelable = true; return; }
      this.input_lock = true;
    },

    onDiscountChange(){
      if ( !this.checkLocked() ) return;
      this.CHANGE_DISCOUNT( this.account_info.discount );
    },

    onProductPressEsc(){
      if ( this.input_lock ) { this.input_lock = false; return; }
      if ( this.showModal_ConstrDel || this.showModal_AccountSave || this.showModal_AccountSend || this.showModal_AccountPrint ) { this.closeModal(); return; }
      this.$router.push({
        name: 'records',
        params: {
          'account_id': this.account_info.account_id,
          'account_info': this.account_info,
          //'account': this.record_data.account,
          //'customer': this.record_data.customer,
        },
      });
    },

    onSelectPressEsc(){
      this.select_cursor.pos = this.select_cursor.min;
      this.select_cursor.max = this.select_cursor.min;
      this.select_cursor.active = false;
      const dataCellEl = this.$el.querySelector(`[data-cell-col="${this.cursor_pos.col}"][data-cell-row="${this.cursor_pos.row}"]`);
      const selectDropdownEl = dataCellEl.querySelector(".c-flex-table__select-dropdown");
      selectDropdownEl.classList.remove("is-active2");

      //const num_input = document.getElementById("NumericInput");
      //if ( num_input!= undefined ){
        //num_input.parentNode.removeChild(num_input);
        //this.showModal_Edit = false;
      //}
      this.NumericInputRemove();
      //const num_inputfset = document.getElementById("NumericInputFSet");
      //if ( num_inputfset!= undefined ) num_inputfset.parentNode.removeChild(num_inputfset);
      this.NumericInputFSetRemove();
    },
    NumericInputRemove(){
      let num_input;
      while ( ( num_input = document.getElementById("NumericInput") ) != undefined ){
        num_input.parentNode.removeChild( num_input );
      }
      this.showModal_Edit = false;
    },
    NumericInputFSetRemove(){
      let num_inputfset;
      while ( ( num_inputfset = document.getElementById("NumericInputFSet") ) != undefined ){
        num_inputfset.parentNode.removeChild( num_inputfset );
      }
    },

    onProductPressArrowDown(){
      this.cursor_pos.last_direct = "down";
      this.cursor_pos.increaseRow();
      this.setPicture();
      //this.setDraw();
      const dataCellEl = this.$el.querySelector(`[data-cell-col="${this.cursor_pos.col}"][data-cell-row="${this.cursor_pos.row}"]`);
      const scrollY = -(this.$refs.table.getBoundingClientRect().bottom - dataCellEl.getBoundingClientRect().bottom) + 20;
      if (scrollY > 0) this.$refs.table.scrollBy(0, scrollY);
    },

    onProductCellClick(col, row/*, cur_option_pos, options_amount, dataCellEl*/) {

      //this.onProductPressEnter();
      //this.modeSet("select");
      //console.log( col, row );


      if (this.select_cursor.active) return;
      if ( this.showModal_Edit == true ) return;
      if(this.isCursorOnCell(col,row)) {
        if(!this.select_cursor.close) {
          this.onProductPressEnter();
          this.modeSet("select");
        }
        this.select_cursor.close = false;
        //this.select_cursor.active = !this.select_cursor.active;
      } else {
        this.setCursorPos(col, row);
        this.cursor_pos.showCursor();
        this.setPicture();
        this.setDraw();
        this.modeSet("table","2");
      }
    },

    onCatalogCellClick( col, row ){
      if ( this.select_cursor.active ) return;
      if ( this.showModal_Edit == true ) return;
      if ( !this.modeSet("catalog") ) return;
      if ( this.isCursorOnCatCell( col,row ) ) {
        this.cat_cursor_pos.showCursor();
        if ( this.cat_cursor_pos.col == 2 || this.cat_cursor_pos.col == 3 || this.cat_cursor_pos.col == 4 ){
          this.onCatalogPressEnter(); this.modeSet("editInput");
        }
      } else {
        this.setCatalogCursorPos( col, row );
        this.cat_cursor_pos.showCursor();
      }

/*
      private timeoutId = null;
onClick() {
        if(!this.timeoutId)
        {
            this.timeoutId = setTimeout(() => {
                // simple click
            }, 50);//tolerance in ms
        }else{
            clearTimeout(this.timeoutId);
            // double click
        }
    }
*/

      /*if(this.isCursorOnCell(col,row)) {
        if(!this.select_cursor.c lose) this.onProductPress Enter();
        this.select_cursor.c lose = false;
        //this.select_cursor.active = !this.select_cursor.active;
      } else {
        this.setCatalogCursorPos(col, row);
        this.cursor_pos.showCursor();
      }*/
    },

    onTreeCellClick(col, row) {
      if (this.select_cursor.active) return;
      if (!this.modeSet("tree")) return;
      if( col == this.tree_cursor_pos.col && row == this.tree_cursor_pos.row ){
        this.onTreePressEnter();
      } else {
        this.setTreeCursorPos(col, row);
        this.tree_cursor_pos.showCursor();
      }
    },

    onProductSelectOptionClick(property_value, property_name, product_index) {

      if ( property_value.indexOf("==") != -1 ) return;
      let prev_property_value = this.productsGGG[product_index][property_name];

      //---------- взять значение из поля ввода, если оно есть
      //const num_input = document.getElementById("NumericInput");
      //if ( num_input!= undefined ){
        ////if ( num_input.value != "" ) property_value = num_input.value;
        //num_input.parentNode.removeChild(num_input);
      //}
      this.NumericInputRemove();
      //const num_inputfset = document.getElementById("NumericInputFSet");
      //if ( num_inputfset!= undefined ) num_inputfset.parentNode.removeChild(num_inputfset);
      //this.showModal_Edit = false;
      this.NumericInputFSetRemove();

      if(prev_property_value != property_value) this.onProductSelectChange(property_value, property_name, product_index);

      this.select_cursor.pos = this.select_cursor.min;
      this.select_cursor.max = this.select_cursor.min;
      this.select_cursor.active = false;
      this.select_cursor.close = true;
      const dataCellEl = document.querySelector(`[data-cell-col="${this.cursor_pos.col}"][data-cell-row="${this.cursor_pos.row}"]`);
      if (dataCellEl == undefined ) alert("dataCellEl == undefined");
      if (dataCellEl.classList.contains("is-hid") || dataCellEl.classList.contains("is-dis") || dataCellEl.classList.contains("is-serv") ) return;
      const selectDropdownEl = dataCellEl.querySelector(".c-flex-table__select-dropdown");
      if (selectDropdownEl == undefined ) alert("selectDropdownEl == undefined");
      selectDropdownEl.classList.remove("is-active2");

      this.onProductPropertyValueChange();
      this.forceRerender();
      this.ShowHideRow();
      this.cursor_pos.increaseRowFlag = 2;
      this.modeSet("table","3");

      //this.onProductPressArrowDown();
      //this.cursor_pos.last_direct = "down";

    },
    getCurrentSelectOptionPos(property_name, product_index) {
        //console.log("getCurrentSelectOptionPos");
        let curOption = this.productsGGG[product_index][property_name];
        if (!curOption) return this.select_cursor.min;
        /*if ( property_name == "[factory]" ){
          console.log(this.productsGGG[product_index].options[property_name]);
        }*/
        let optionsArr = this.productsGGG[product_index].options[property_name];
        if ( optionsArr != undefined ) return optionsArr.findIndex((option) => option == curOption); else return 0;
    },
    keyboardListener(e) {
      if ( this.lock_keys ) { e.preventDefault(); console.log("Blocked speed input..."); return; }
      const efocus = document.querySelector(":focus");
      let dataCellEl, scrollX, scrollY, selectDropdownEl, curSelectDrodpownOptionEl;
      let trueFlag, moveFlag, count, num_input;
      if ( efocus!=null ){
        if ( efocus.id == "iddate1" || efocus.id == "idkp" || efocus.id == "idcustomer" || efocus.id == "iddeal" || efocus.id == "idaccount" || efocus.id == "idcomment" || efocus.id == "idclient_id" ) return;
      }

      if ( !this.showModal_Edit ){
        e.preventDefault();
      }
      switch( this.mode ){
        case "table":{
          switch (e.key) {
            case "Tab":
              if ( this.modeSet("catalog") ) { this.cursor_pos.hideCursor(); this.cat_cursor_pos.showCursor(); this.modeSet("catalog"); } break;
            case "ArrowLeft":
             this.cursor_pos.last_direct = "left";
              if( e.ctrlKey ) this.onProductMoveLeft();
              else {
                this.cursor_pos.decreaseCol();
                this.setPicture(); this.setDraw(); this.onSpecViewFill();
              }
              dataCellEl = this.$el.querySelector(`[data-cell-col="${this.cursor_pos.col}"][data-cell-row="${this.cursor_pos.row}"]`);
              scrollX = -this.$refs.table.getBoundingClientRect().left + this.sticky_offsets.x + dataCellEl.getBoundingClientRect().left;
              if(scrollX < 0) this.$refs.table.scrollBy(scrollX, 0);
              break;
            case "ArrowRight":
              this.cursor_pos.last_direct = "right";
              if( e.ctrlKey ) this.onProductMoveRight();
              else {
                this.cursor_pos.increaseCol();
                this.setPicture(); this.setDraw(); this.onSpecViewFill();
              }
              dataCellEl = this.$el.querySelector(`[data-cell-col="${this.cursor_pos.col}"][data-cell-row="${this.cursor_pos.row}"]`);
              scrollX = -(this.$refs.table.getBoundingClientRect().right - dataCellEl.getBoundingClientRect().right) + 20;
              if(scrollX > 0) this.$refs.table.scrollBy(scrollX, 0);
              break;
            case "ArrowUp":
              this.cursor_pos.last_direct = "up";
              if ( this.cursor_pos.active ) {
                this.cursor_pos.decreaseRow();
                this.setPicture();
                dataCellEl = this.$el.querySelector(`[data-cell-col="${this.cursor_pos.col}"][data-cell-row="${this.cursor_pos.row}"]`);
                scrollY = -this.$refs.table.getBoundingClientRect().top + this.sticky_offsets.y + dataCellEl.getBoundingClientRect().top;
                if(scrollY < 0) this.$refs.table.scrollBy(0, scrollY);
              } 
              break;
            case "ArrowDown":
              this.onProductPressArrowDown(); break;
            case "Home":
              if( !e.ctrlKey ) { this.cursor_pos.FirstCol(); } else { this.cursor_pos.FirstCell(); }
              this.setPicture();
              this.setDraw();
              dataCellEl = this.$el.querySelector(`[data-cell-col="${this.cursor_pos.col}"][data-cell-row="${this.cursor_pos.row}"]`);
              scrollX = -this.$refs.table.getBoundingClientRect().left + this.sticky_offsets.x + dataCellEl.getBoundingClientRect().left;
              scrollY = -this.$refs.table.getBoundingClientRect().top + this.sticky_offsets.y + dataCellEl.getBoundingClientRect().top;
              if(scrollX > 0) scrollX = 0;
              if(!e.ctrlKey && scrollY > 0) scrollY = 0;
              if(scrollX < 0 || scrollY < 0) this.$refs.table.scrollBy(scrollX, scrollY);
              break;
            case "End":
              if(!e.ctrlKey) { this.cursor_pos.LastCol(); } else {this.cursor_pos.LastCell(); }
              this.setPicture();
              this.setDraw();
              dataCellEl = this.$el.querySelector(`[data-cell-col="${this.cursor_pos.col}"][data-cell-row="${this.cursor_pos.row}"]`);
              scrollX = -(this.$refs.table.getBoundingClientRect().right - dataCellEl.getBoundingClientRect().right) + 20;
              scrollY = -(this.$refs.table.getBoundingClientRect().bottom - dataCellEl.getBoundingClientRect().bottom) + 20;
              if(scrollX < 0) scrollX = 0;
              if(scrollY < 0) scrollY = 0;
              if(scrollX > 0 || scrollY > 0) this.$refs.table.scrollBy(scrollX, scrollY);
              break;
            case "PageUp":
              trueFlag = true; moveFlag = false; count=0;
              do {
                count++; if (count>20) break;
                dataCellEl = this.$el.querySelector(`[data-cell-col="${this.cursor_pos.col}"][data-cell-row="${this.cursor_pos.row}"]`);
                scrollY = dataCellEl.getBoundingClientRect().top - ( this.$refs.table.getBoundingClientRect().top - this.sticky_offsets.y );
                if( scrollY <= 3 ){ break; }
                this.cursor_pos.decreaseRow(); moveFlag = true; // переместиться наверх видимой области
              } while ( trueFlag );
              if ( !moveFlag ){
                let numStep = Math.floor( ( this.$refs.table.getBoundingClientRect().height + this.sticky_offsets.y ) / dataCellEl.getBoundingClientRect().height );
                for( let i = 0; i<numStep; i++ ) { this.cursor_pos.decreaseRow(); } // переместиться на страницу вверх
                scrollY = -dataCellEl.getBoundingClientRect().height * numStep;
              }
              this.$refs.table.scrollBy(0, scrollY);
              // dataCellEl.getBoundingClientRect().height // высота курсора
              // this.$refs.table.getBoundingClientRect().height // высота таблицы
              // this.sticky_offsets.y // высота замороженной верхней строки
              // this.$refs.table.getBoundingClientRect().height-this.sticky_offsets.y; // высота видимой области
              // Math.floor((this.$refs.table.getBoundingClientRect().height+this.sticky_offsets.y)/dataCellEl.getBoundingClientRect().height); // количество строк в области
              // надо вынести общие вычисления в отдельную функция, котрая будет получать размеры области и курсора и возвращать количество движений и размер скролла
              // надо добавить в функции increase и decrease параметр, указывающий на количество перемещений, чтобы не вызывать эти функции в цикле
              break;
            case "PageDown":
              trueFlag = true; moveFlag = false, count = 0;
              do {
                count++; if (count>20) break;
                dataCellEl = this.$el.querySelector(`[data-cell-col="${this.cursor_pos.col}"][data-cell-row="${this.cursor_pos.row}"]`);
                scrollY = dataCellEl.getBoundingClientRect().top + dataCellEl.getBoundingClientRect().height - 30 -
                          ( this.$refs.table.getBoundingClientRect().top + this.sticky_offsets.y + this.$refs.table.getBoundingClientRect().height ); //
                if( scrollY > -5 ){ break; }
                this.cursor_pos.increaseRow(); moveFlag = true; // переместиться вниз видимой области
              } while ( trueFlag );
              if ( !moveFlag ){
                let numStep = Math.floor((this.$refs.table.getBoundingClientRect().height+this.sticky_offsets.y) / dataCellEl.getBoundingClientRect().height);
                for( let i = 0; i<numStep; i++ ) { this.cursor_pos.increaseRow(); } // переместиться на страницу вниз
                scrollY = dataCellEl.getBoundingClientRect().height * numStep;
              }
              this.$refs.table.scrollBy(0, scrollY);
              break;
            case "Enter": this.onProductPressEnter(); this.modeSet("select"); break;
            case "Escape": this.onProductPressEsc(); this.modeSet("accountSave"); break;
            case "Delete": 
              if ( e.shiftKey && e.ctrlKey ) { this.onConstrDeleteYes(); break; }
              if ( e.shiftKey ) { this.onDeleteClick(); this.modeSet("constrDel"); } else { this.onProductPropertyValueDelete(); } break;
            case "Insert": this.onProductAdd(); break;
            case "F9": this.onProductCopy( e ); break;
            case "F2": this.onAccountProductsUpdate(true); break;
            case "F5": if ( e.shiftKey ) { this.onProductsPrint(); } else { this.onAccountProductsPrint(); } break;
          }
          break;
        }

        case "select":{
          switch (e.key) {
            case "Tab": break;
            case "ArrowLeft": break;
            case "ArrowRight": break;
            case "ArrowUp":
              this.select_cursor.decreasePos();
              selectDropdownEl = this.$el.querySelector(`[data-cell-col="${this.cursor_pos.col}"][data-cell-row="${this.cursor_pos.row}"]`).querySelector(".c-flex-table__select-dropdown");
              curSelectDrodpownOptionEl = selectDropdownEl.children[this.select_cursor.pos];
              scrollY = -selectDropdownEl.getBoundingClientRect().top + curSelectDrodpownOptionEl.getBoundingClientRect().top;
              if(scrollY < 0) selectDropdownEl.scrollBy(0, scrollY);
              this.setPictureVal( curSelectDrodpownOptionEl.innerText );
              num_input = document.getElementById("NumericInput");
              if ( num_input != undefined ) {
                num_input.value = curSelectDrodpownOptionEl.innerText;
              }
            break;
            case "ArrowDown":
              this.select_cursor.increasePos();
              selectDropdownEl = this.$el.querySelector(`[data-cell-col="${this.cursor_pos.col}"][data-cell-row="${this.cursor_pos.row}"]`).querySelector(".c-flex-table__select-dropdown");
              curSelectDrodpownOptionEl = selectDropdownEl.children[this.select_cursor.pos];
              scrollY = -selectDropdownEl.getBoundingClientRect().bottom + curSelectDrodpownOptionEl.getBoundingClientRect().bottom;
              if(scrollY > 0) selectDropdownEl.scrollBy(0, scrollY);
              this.setPictureVal( curSelectDrodpownOptionEl.innerText );
              num_input = document.getElementById("NumericInput");
              if ( num_input != undefined ) {
                num_input.value = curSelectDrodpownOptionEl.innerText;
              }
            break;
            case "Home":
              this.select_cursor.FirstPos();
              selectDropdownEl = this.$el.querySelector(`[data-cell-col="${this.cursor_pos.col}"][data-cell-row="${this.cursor_pos.row}"]`).querySelector(".c-flex-table__select-dropdown");
              curSelectDrodpownOptionEl = selectDropdownEl.children[this.select_cursor.pos];
              scrollY = -selectDropdownEl.getBoundingClientRect().top + curSelectDrodpownOptionEl.getBoundingClientRect().top;
              if(scrollY < 0) selectDropdownEl.scrollBy(0, scrollY);
              this.setPictureVal( curSelectDrodpownOptionEl.innerText );
              num_input = document.getElementById("NumericInput");
              if (num_input!=undefined) num_input.value = curSelectDrodpownOptionEl.innerText;
              break;
            case "End":
              this.select_cursor.LastPos();
              selectDropdownEl = this.$el.querySelector(`[data-cell-col="${this.cursor_pos.col}"][data-cell-row="${this.cursor_pos.row}"]`).querySelector(".c-flex-table__select-dropdown");
              curSelectDrodpownOptionEl = selectDropdownEl.children[this.select_cursor.pos];
              scrollY = -selectDropdownEl.getBoundingClientRect().bottom + curSelectDrodpownOptionEl.getBoundingClientRect().bottom;
              if(scrollY > 0) selectDropdownEl.scrollBy(0, scrollY);
              this.setPictureVal( curSelectDrodpownOptionEl.innerText );
              num_input = document.getElementById("NumericInput");
              if (num_input!=undefined) num_input.value = curSelectDrodpownOptionEl.innerText;
              break;
            case "PageUp":
              trueFlag = true; moveFlag = false; count =  0;
              selectDropdownEl = this.$el.querySelector(`[data-cell-col="${this.cursor_pos.col}"][data-cell-row="${this.cursor_pos.row}"]`).querySelector(".c-flex-table__select-dropdown");
              do{
                count++; if (count>20) break;
                curSelectDrodpownOptionEl = selectDropdownEl.children[this.select_cursor.pos];
                scrollY = curSelectDrodpownOptionEl.getBoundingClientRect().top - selectDropdownEl.getBoundingClientRect().top;
                if( scrollY <= 3 ){ break; }
                this.select_cursor.decreasePos(); moveFlag = true; // переместиться наверх видимой области
              } while( trueFlag );
              if ( !moveFlag ){
                curSelectDrodpownOptionEl = selectDropdownEl.children[this.select_cursor.pos];
                let numStep = Math.floor(selectDropdownEl.getBoundingClientRect().height / curSelectDrodpownOptionEl.getBoundingClientRect().height);
                for( let i = 0; i<numStep; i++ ) { this.select_cursor.decreasePos(); } // переместиться на страницу вверх
                scrollY = -curSelectDrodpownOptionEl.getBoundingClientRect().height * numStep;
              }
              selectDropdownEl.scrollBy(0, scrollY);
              this.setPictureVal( curSelectDrodpownOptionEl.innerText );
              // dataCellEl.getBoundingClientRect().height // высота курсора
              // this.$refs.table.getBoundingClientRect().height // высота таблицы
              // this.sticky_offsets.y // высота замороженной верхней строки
              // this.$refs.table.getBoundingClientRect().height-this.sticky_offsets.y; // высота видимой области
              // Math.floor((this.$refs.table.getBoundingClientRect().height+this.sticky_offsets.y)/dataCellEl.getBoundingClientRect().height); // количество строк в области
              // надо вынести общие вычисления в отдельную функция, котрая будет получать размеры области и курсора и возвращать количество движений и размер скролла
              // надо добавить в функции increase и decrease параметр, указывающий на количество перемещений, чтобы не вызывать эти функции в цикле
              break;
            case "PageDown":
              trueFlag = true; moveFlag = false; count = 0;
              selectDropdownEl = this.$el.querySelector(`[data-cell-col="${this.cursor_pos.col}"][data-cell-row="${this.cursor_pos.row}"]`).querySelector(".c-flex-table__select-dropdown");
              do{
                count++; if (count>20) break;
                curSelectDrodpownOptionEl = selectDropdownEl.children[this.select_cursor.pos];
                scrollY = curSelectDrodpownOptionEl.getBoundingClientRect().top + curSelectDrodpownOptionEl.getBoundingClientRect().height - 
                          (selectDropdownEl.getBoundingClientRect().top + selectDropdownEl.getBoundingClientRect().height);
                if( scrollY > -5 ){ break; }
                this.select_cursor.increasePos(); moveFlag = true; // переместиться вниз видимой области
              } while( trueFlag );
              if ( !moveFlag ){
                curSelectDrodpownOptionEl = selectDropdownEl.children[this.select_cursor.pos];
                let numStep = Math.floor(selectDropdownEl.getBoundingClientRect().height / curSelectDrodpownOptionEl.getBoundingClientRect().height);
                for( let i = 0; i<numStep; i++ ) { this.select_cursor.increasePos(); } // переместиться на страницу вниз
                scrollY = curSelectDrodpownOptionEl.getBoundingClientRect().height * numStep; //alert(numStep);
              }
              selectDropdownEl.scrollBy(0, scrollY);
              this.setPictureVal( curSelectDrodpownOptionEl.innerText );
              break;
            case "Enter": if ( this.onSelectPressEnter() ) { this.modeSet("table","4"); } break;
            case "Escape": this.onSelectPressEsc(); this.modeSet("table","5"); break;
            case "Delete": break;
            case "Insert": break;
            case "F9": break;
            case "F2": this.onAccountProductsUpdate(true); break;
            case "F5": if ( e.shiftKey ) { this.onProductsPrint(); } else { this.onAccountProductsPrint(); } break;
          }
          break;
        }

        case "catalog":{
          switch (e.key) {
            case "Tab": if ( this.modeSet("table","6") ) { this.cat_cursor_pos.hideCursor(); this.tree_cursor_pos.hideCursor(); this.cursor_pos.showCursor(); } break;
            case "ArrowLeft": this.cat_cursor_pos.decreaseCol(); break;
            case "ArrowRight": this.cat_cursor_pos.increaseCol(); break;
            case "ArrowUp":
              if( e.ctrlKey ) this.onProductMoveUp(); else this.cat_cursor_pos.decreaseRow();
              dataCellEl = this.$el.querySelector(`[cat-cell-col="${this.cat_cursor_pos.col}"][cat-cell-row="${this.cat_cursor_pos.row}"]`);
              scrollY = -this.$refs.catalog.getBoundingClientRect().top + this.sticky_offsets.y_cat + dataCellEl.getBoundingClientRect().top;
              if(scrollY < 0) this.$refs.catalog.scrollBy(0, scrollY);              
              break;
            case "ArrowDown":
              if( e.ctrlKey ) this.onProductMoveDown(); else this.cat_cursor_pos.increaseRow();
              dataCellEl = this.$el.querySelector(`[cat-cell-col="${this.cat_cursor_pos.col}"][cat-cell-row="${this.cat_cursor_pos.row}"]`);
              scrollY = -(this.$refs.catalog.getBoundingClientRect().bottom - dataCellEl.getBoundingClientRect().bottom) + 15;
              if (scrollY > 0) this.$refs.catalog.scrollBy(0, scrollY);
              break;
            case "Home":
              if ( !e.ctrlKey ) this.cat_cursor_pos.FirstCol(); else this.cat_cursor_pos.FirstCell();
              dataCellEl = this.$el.querySelector(`[cat-cell-col="${this.cat_cursor_pos.col}"][cat-cell-row="${this.cat_cursor_pos.row}"]`);
              scrollX = -this.$refs.catalog.getBoundingClientRect().left + this.sticky_offsets.x + dataCellEl.getBoundingClientRect().left;
              scrollY = -this.$refs.catalog.getBoundingClientRect().top + this.sticky_offsets.y_cat + dataCellEl.getBoundingClientRect().top;
              if(scrollX > 0) scrollX = 0;
              if(!e.ctrlKey && scrollY > 0) scrollY = 0;
              if(scrollX < 0 || scrollY < 0) this.$refs.catalog.scrollBy(scrollX, scrollY);
              break;
            case "End":
              if( !e.ctrlKey ) this.cat_cursor_pos.LastCol(); else this.cat_cursor_pos.LastCell();
              dataCellEl = this.$el.querySelector(`[cat-cell-col="${this.cat_cursor_pos.col}"][cat-cell-row="${this.cat_cursor_pos.row}"]`);
              scrollX = -(this.$refs.catalog.getBoundingClientRect().right - dataCellEl.getBoundingClientRect().right) + 20;
              scrollY = -(this.$refs.catalog.getBoundingClientRect().bottom - dataCellEl.getBoundingClientRect().bottom) + 15;
              if(scrollX < 0) scrollX = 0;
              if(scrollY < 0) scrollY = 0;
              if(scrollX > 0 || scrollY > 0) this.$refs.catalog.scrollBy(scrollX, scrollY);
              break;
            case "PageUp":
              trueFlag = true; moveFlag = false; count=0;
              do {
                count++; if (count>30) break;
                dataCellEl = this.$el.querySelector(`[cat-cell-col="${this.cat_cursor_pos.col}"][cat-cell-row="${this.cat_cursor_pos.row}"]`);
                scrollY = dataCellEl.getBoundingClientRect().top - ( this.$refs.catalog.getBoundingClientRect().top - this.sticky_offsets.y_cat );
                if( scrollY <= 3 ){ break; }
                this.cat_cursor_pos.decreaseRow(); moveFlag = true;
              } while ( trueFlag );
              if ( !moveFlag ){
                let numStep = Math.floor( ( this.$refs.catalog.getBoundingClientRect().height + this.sticky_offsets.y_cat ) / dataCellEl.getBoundingClientRect().height );
                for( let i = 0; i<numStep; i++ ) { this.cat_cursor_pos.decreaseRow(); }
                scrollY = -dataCellEl.getBoundingClientRect().height * numStep;
              }
              this.$refs.catalog.scrollBy(0, scrollY);
              break;
            case "PageDown":
              trueFlag = true; moveFlag = false, count = 0;
              do {
                count++; if (count>30) break;
                dataCellEl = this.$el.querySelector(`[cat-cell-col="${this.cat_cursor_pos.col}"][cat-cell-row="${this.cat_cursor_pos.row}"]`);
                scrollY = dataCellEl.getBoundingClientRect().top + dataCellEl.getBoundingClientRect().height - 30 -
                          ( this.$refs.catalog.getBoundingClientRect().top + this.sticky_offsets.y_cat + this.$refs.catalog.getBoundingClientRect().height );
                if( scrollY > -5 ){ break; }
                this.cat_cursor_pos.increaseRow(); moveFlag = true;
              } while ( trueFlag );
              if ( !moveFlag ){
                let numStep = Math.floor((this.$refs.catalog.getBoundingClientRect().height+this.sticky_offsets.y_cat) / dataCellEl.getBoundingClientRect().height);
                for( let i = 0; i<numStep; i++ ) { this.cat_cursor_pos.increaseRow(); }
                scrollY = dataCellEl.getBoundingClientRect().height * numStep;
              }
              this.$refs.catalog.scrollBy(0, scrollY);
              break;
            case "Enter": if ( this.cat_cursor_pos.col == 2 || this.cat_cursor_pos.col == 3 || this.cat_cursor_pos.col == 4 ){ this.onCatalogPressEnter(); this.modeSet("editInput"); } break;
            case "Escape": this.onProductPressEsc(); this.modeSet("accountSave"); break;
            case "Delete": if(e.shiftKey) { this.onCatalogDelete(); break; } else { /*this.onCatalogPropertyValueDelete();*/ break; }
            case "Insert": this.onCatalogAdd(); break;
            case "F9": this.onCatalogCopy( e ); break;
            case "F2": this.onAccountProductsUpdate(true); break;
            case "F5": if ( e.shiftKey ) { this.onProductsPrint(); } else { this.onAccountProductsPrint(); } break;
          }
          break;
        }

        case "tree":{
          switch (e.key) {
            case "Tab": if ( this.modeSet("table","7") ) { this.cat_cursor_pos.hideCursor(); this.tree_cursor_pos.hideCursor(); this.cursor_pos.showCursor(); } break;
            case "ArrowLeft": this.tree_cursor_pos.decreaseCol(); break;
            case "ArrowRight": this.tree_cursor_pos.increaseCol(); break;
            case "ArrowUp":
              this.tree_cursor_pos.decreaseRow();
              dataCellEl = this.$el.querySelector(`[tree-cell-col="${this.tree_cursor_pos.col}"][tree-cell-row="${this.tree_cursor_pos.row}"]`);
              scrollY = -this.$refs.catalog.getBoundingClientRect().top + this.sticky_offsets.y_cat + dataCellEl.getBoundingClientRect().top;
              if(scrollY < 0) this.$refs.catalog.scrollBy(0, scrollY);              
              break;
            case "ArrowDown":
              this.tree_cursor_pos.increaseRow();
              dataCellEl = this.$el.querySelector(`[tree-cell-col="${this.tree_cursor_pos.col}"][tree-cell-row="${this.tree_cursor_pos.row}"]`);
              scrollY = -(this.$refs.catalog.getBoundingClientRect().bottom - dataCellEl.getBoundingClientRect().bottom) + 15;
              if (scrollY > 0) this.$refs.catalog.scrollBy(0, scrollY);
              break;
            case "Home":
              if ( !e.ctrlKey ) this.tree_cursor_pos.FirstCol(); else this.tree_cursor_pos.FirstCell();
              dataCellEl = this.$el.querySelector(`[tree-cell-col="${this.tree_cursor_pos.col}"][tree-cell-row="${this.tree_cursor_pos.row}"]`);
              scrollX = -this.$refs.catalog.getBoundingClientRect().left + this.sticky_offsets.x + dataCellEl.getBoundingClientRect().left;
              scrollY = -this.$refs.catalog.getBoundingClientRect().top + this.sticky_offsets.y_cat + dataCellEl.getBoundingClientRect().top;
              if(scrollX > 0) scrollX = 0;
              if(!e.ctrlKey && scrollY > 0) scrollY = 0;
              if(scrollX < 0 || scrollY < 0) this.$refs.catalog.scrollBy(scrollX, scrollY);
              break;
            case "End":
              if( !e.ctrlKey ) this.tree_cursor_pos.LastCol(); else this.tree_cursor_pos.LastCell();
              dataCellEl = this.$el.querySelector(`[tree-cell-col="${this.tree_cursor_pos.col}"][tree-cell-row="${this.tree_cursor_pos.row}"]`);
              scrollX = -(this.$refs.catalog.getBoundingClientRect().right - dataCellEl.getBoundingClientRect().right) + 20;
              scrollY = -(this.$refs.catalog.getBoundingClientRect().bottom - dataCellEl.getBoundingClientRect().bottom) + 15;
              if(scrollX < 0) scrollX = 0;
              if(scrollY < 0) scrollY = 0;
              if(scrollX > 0 || scrollY > 0) this.$refs.catalog.scrollBy(scrollX, scrollY);
              break;
            case "PageUp":
              trueFlag = true; moveFlag = false; count=0;
              do {
                count++; if (count>30) break;
                dataCellEl = this.$el.querySelector(`[tree-cell-col="${this.tree_cursor_pos.col}"][tree-cell-row="${this.tree_cursor_pos.row}"]`);
                scrollY = dataCellEl.getBoundingClientRect().top - ( this.$refs.catalog.getBoundingClientRect().top - this.sticky_offsets.y_cat );
                if( scrollY <= 3 ){ break; }
                this.tree_cursor_pos.decreaseRow(); moveFlag = true;
              } while ( trueFlag );
              if ( !moveFlag ){
                let numStep = Math.floor( ( this.$refs.catalog.getBoundingClientRect().height + this.sticky_offsets.y_cat ) / dataCellEl.getBoundingClientRect().height );
                for( let i = 0; i<numStep; i++ ) { this.tree_cursor_pos.decreaseRow(); }
                scrollY = -dataCellEl.getBoundingClientRect().height * numStep;
              }
              this.$refs.catalog.scrollBy(0, scrollY);
              break;
            case "PageDown":
              trueFlag = true; moveFlag = false, count = 0;
              do {
                count++; if (count>30) break;
                dataCellEl = this.$el.querySelector(`[tree-cell-col="${this.tree_cursor_pos.col}"][tree-cell-row="${this.tree_cursor_pos.row}"]`);
                scrollY = dataCellEl.getBoundingClientRect().top + dataCellEl.getBoundingClientRect().height - 30 -
                          ( this.$refs.catalog.getBoundingClientRect().top + this.sticky_offsets.y_cat + this.$refs.catalog.getBoundingClientRect().height );
                if( scrollY > -5 ){ break; }
                this.tree_cursor_pos.increaseRow(); moveFlag = true;
              } while ( trueFlag );
              if ( !moveFlag ){
                let numStep = Math.floor((this.$refs.catalog.getBoundingClientRect().height+this.sticky_offsets.y_cat) / dataCellEl.getBoundingClientRect().height);
                for( let i = 0; i<numStep; i++ ) { this.tree_cursor_pos.increaseRow(); }
                scrollY = dataCellEl.getBoundingClientRect().height * numStep;
              }
              this.$refs.catalog.scrollBy(0, scrollY);
              break;
            case "Enter": this.onTreePressEnter(); break;
            case "Backspace": 
              //alert( this.tree[this.tree_cursor_pos.row-1].parentcode );
              if ( this.filter != "" ){
                this.filter = this.filter.substring(0,this.filter.length-1);
                this.FETCH_TREE( { thisobj:this, mode:this.tree_on, num_node:"", filter:this.filter } );
              } else {
                let node = this.tree[this.tree_cursor_pos.row-1];
                if ( this.tree_cursor_pos.row > 1 ) node = this.tree[this.tree_cursor_pos.row-2];
                this.FETCH_TREE( { thisobj:this, mode:this.tree_on, num_node:node.parentcode, filter:"" } );
              }
              break;
            case "Escape": this.modeSet("catalog"); break;
            case "Delete": break;
            case "Insert": break;
            case "F9": break;
            case "F2": this.onAccountProductsUpdate(true); break;
            case "F5": if ( e.shiftKey ) { this.onProductsPrint(); } else { this.onAccountProductsPrint(); } break;
            default:
              if ( !isNaN( e.key ) || ( e.key >= "a" && e.key <= "z" ) || e.key == '.' ){
                this.filter = this.filter + e.key;
                this.FETCH_TREE( { thisobj:this, mode:this.tree_on, num_node:"", filter:this.filter } );
              }
              break;
          }
          break;
        }

        case "accout":{
          break;
        }

        case "editInput":{
          switch (e.key) {
            case "Enter":
              num_input = document.getElementById("NumericInput");
              if ( num_input!= undefined ){
                this.SET_CATALOG_PROPERTY( {value:num_input.value, col:this.cat_cursor_pos.col, row:this.cat_cursor_pos.row} );
                //num_input.parentNode.removeChild(num_input);
                //this.showModal_Edit = false;
              }
              this.NumericInputRemove();
              this.NumericInputFSetRemove();
              this.modeSet("catalog"); break;
            case "Escape":
              //num_input = document.getElementById("NumericInput");
              //if ( num_input!= undefined ){
                //num_input.parentNode.removeChild(num_input);
                //this.showModal_Edit = false;
              //}
              this.NumericInputRemove();
              this.NumericInputFSetRemove();
              this.modeSet("catalog"); break;
          }
          break;
        }

        case "constrDel":{
          switch (e.key) {
            case "ArrowLeft": case "Home": this.showModal_ConstrDelPos = 0; break;
            case "ArrowRight": case "End": this.showModal_ConstrDelPos = 1; break;
            case "Enter":
              if ( this.showModal_ConstrDelPos == 0 ) this.onConstrDeleteYes();
              this.closeModal(); this.modeSet(this.mode_prev); break;
            case "Escape":
              this.closeModal(); this.modeSet(this.mode_prev); break;
          }
          break;
        }

        case "accountSave":{
          switch (e.key) {
            case "ArrowLeft": this.showModal_AccountSavePos = this.showModal_AccountSavePos <= 1 ? 0 : this.showModal_AccountSavePos - 1; break;
            case "Home": this.showModal_AccountSavePos = 0; break;
            case "ArrowRight": this.showModal_AccountSavePos = this.showModal_AccountSavePos >= 1 ? 2 : this.showModal_AccountSavePos + 1; break;
            case "End": this.showModal_AccountSavePos = 2; break;
            case "Enter":
              if (this.showModal_AccountSavePos == 0) this.OnProductsSave();
              if (this.showModal_AccountSavePos == 1) this.OnProductsNoSave();
              this.closeModal(); this.modeSet(this.mode_prev); break;
            case "Escape":
              this.closeModal(); this.modeSet(this.mode_prev); break;
          }
          break;
        }

        case "accountSend":{
          switch (e.key) {
            case "ArrowLeft": case "Home": this.showModal_AccountSendPos = 0; break;
            case "ArrowRight": case "End": this.showModal_AccountSendPos = 1; break;
            case "Enter":
              if (this.showModal_AccountSendPos == 0) this.OnProductsSend();
              this.closeModal(); this.modeSet(this.mode_prev); break;
            case "Escape":
              this.closeModal(); this.modeSet(this.mode_prev); break;
          }
          break;
        }

        case "accountPrint":{
          switch (e.key) {
            case "ArrowLeft": this.showModal_AccountPrintPos = this.showModal_AccountPrintPos <= 1 ? 0 : this.showModal_AccountPrintPos - 1; break;
            case "Home": this.showModal_AccountPrintPos = 0; break;
            case "ArrowRight": this.showModal_AccountPrintPos = this.showModal_AccountPrintPos >= 1 ? 2 : this.showModal_AccountPrintPos + 1; break;
            case "End": this.showModal_AccountPrintPos = 2; break;
            case "Enter":
              if (this.showModal_AccountPrintPos == 0) this.onProductsPrint();
              if (this.showModal_AccountPrintPos == 1) this.onProductsPrintDownload();
              this.closeModal(); this.modeSet(this.mode_prev); break;
            case "Escape":
              this.closeModal(); this.modeSet(this.mode_prev); break;
          }
          break;
        }
      }
    },
    modeSet( mode/*,caller*/ ){
      const checkedTableView = document.getElementById("TableView"); if ( mode == 'table' && !checkedTableView.checked ) return false;
      const checkedCatView = document.getElementById("CatView"); if ( ( mode == 'catalog' || mode == 'tree' ) && !checkedCatView.checked ) return false;

      if ( this.mode == "table" || this.mode == "catalog" || this.mode == "tree" ) this.mode_prev = this.mode;
      const cat_view1 = document.getElementsByClassName("divCatView1");
      const cat_view2 = document.getElementsByClassName("divCatView2");
      switch( mode ){
        case "table":          
          for ( let el of cat_view1 ) el.style.display = "block";
          for ( let el of cat_view2 ) el.style.display = "none";
          this.cat_cursor_pos.hideCursor(); this.tree_cursor_pos.hideCursor(); this.cursor_pos.showCursor();
          break;
        case "catalog":
          for ( let el of cat_view1 ) el.style.display = "block";
          for ( let el of cat_view2 ) el.style.display = "none";
          this.cursor_pos.hideCursor(); this.tree_cursor_pos.hideCursor(); this.cat_cursor_pos.showCursor();
          break;
        case "tree":
          for ( let el of cat_view1 ) el.style.display = "none";
          for ( let el of cat_view2 ) el.style.display = "block";
          this.cursor_pos.hideCursor(); this.cat_cursor_pos.hideCursor(); this.tree_cursor_pos.showCursor();
          break;
      }
      this.mode = mode;
      this.filter = "";
      //console.log( "mode:",mode,caller );
      return true;
    },
    showAllCursor(){
      let dataCellEl;
      switch( this.mode ){
        case "table":
          dataCellEl = document.querySelector(`[data-cell-col="${this.cursor_pos.col}"][data-cell-row="${this.cursor_pos.row}"]`);
          if (dataCellEl != null) { if ( !dataCellEl.classList.contains("is-active") ) dataCellEl.classList.add("is-active"); }
          break;
        case "catalog":
          dataCellEl = document.querySelector(`[cat-cell-col="${this.cat_cursor_pos.col}"][cat-cell-row="${this.cat_cursor_pos.row}"]`);
          if (dataCellEl != null) { if ( !dataCellEl.classList.contains("is-active") ) dataCellEl.classList.add("is-cat-active"); }
          break;           
        case "tree":    
          dataCellEl = document.querySelector(`[tree-cell-col="${this.tree_cursor_pos.col}"][tree-cell-row="${this.tree_cursor_pos.row}"]`);
          if (dataCellEl != null) { if ( !dataCellEl.classList.contains("is-active") ) dataCellEl.classList.add("is-tree-active"); }
          break;
        }
    },
    isTable(){
      if ( this.mode == 'table' || this.mode == 'select' ) return true; else return false;
    },
    isCatalog(){
      if ( this.mode == 'catalog' || this.mode == 'tree' || this.mode == 'editInput' ) return true; else return false;
    },
    isCatalogActiveCell(col,row){
      if ( !this.cat_cursor_pos.active ) return false;
      if ( this.cat_cursor_pos.col == col && this.cat_cursor_pos.row == row ) return true;
      return false;
    },
    isTableActiveCell(col,row){
      if ( !this.cursor_pos.active ) return false;
      if ( this.cursor_pos.col == col && this.cursor_pos.row == row ) return true;
      return false;
    },
    
    windowRefresh(){
      const documentH = document.documentElement.clientHeight;                  // высота области отрисовки
      const documentW = document.documentElement.clientWidth;                   // ширина области отрисовки
      const divMenu = document.getElementById("c-buttons-row--container");      // меню
      const accTable = document.getElementById("c-acc-table");                  // данные счета
      const flexTable = document.getElementById("c-flex-table");                // основная таблица
      const divImgView = document.getElementById("divImgView");                 // изображение
      const divDrawView = document.getElementById("divDrawView");               // чертеж
      const divSpecTable = document.getElementById("c-spec-table");             // таблица спецификации
      const divCatView = document.getElementById("divCatView");                 // каталог
      const divSeparateLine = document.getElementById("divSeparateLine");       // разделитель между таблицей и каталогом
      

      const checkedSpecView = document.getElementById("SpecView");              // кнопка спецификации
      const checkedImgView = document.getElementById("ImgView");                // кнопка изображения
      const checkedDrawView = document.getElementById("DrawView");              // кнопка чертежа
      const checkedMenuView = document.getElementById("MenuView");              // кнопка меню
      const checkedAccView = document.getElementById("AccView");                // кнопка счета
      const checkedCatView = document.getElementById("CatView");                // кнопка каталога
      const checkedTableView = document.getElementById("TableView");            // кнопка таблицы
      
      divMenu.style.display = checkedMenuView.checked ? "block" : "none";       // видимость меню
      accTable.style.display = checkedAccView.checked ? "block" : "none";       // видимость данных счета
      divImgView.style.display = checkedImgView.checked ? "block" : "none";     // видимость изображения
      divDrawView.style.display = checkedDrawView.checked ? "block" : "none";   // видимость изображения
      divSpecTable.style.display = checkedSpecView.checked ? "block" : "none";  // видимость спецификации
      divCatView.style.display = checkedCatView.checked ? "" : "none";          // видимость каталога
      flexTable.style.display = checkedTableView.checked ? "" : "none";         // видимость основной таблицы
      divSeparateLine.style.display = checkedTableView.checked && checkedCatView.checked ? "block" : "none"; // видимость линии разделителя

      //---------- расчет размеров основной таблицы
      flexTable.style.width = ( documentW - 44 - divImgView.offsetWidth ) + "px";
      if ( flexTable.style.display != "none" && divCatView.style.display != "none" ){
        divCatView.style.height = "300px";
        flexTable.style.height = ( documentH - 90 - divMenu.offsetHeight - accTable.offsetHeight -  divDrawView.offsetHeight - divSpecTable.offsetHeight - divCatView.offsetHeight) + "px";
      } else if ( flexTable.style.display != "none" ){
        flexTable.style.height = ( documentH - 90 - divMenu.offsetHeight - accTable.offsetHeight -  divDrawView.offsetHeight - divSpecTable.offsetHeight ) + "px";
      } else if ( divCatView.style.display != "none" ){
        divCatView.style.height = ( documentH - 90 - divMenu.offsetHeight - accTable.offsetHeight -  divDrawView.offsetHeight - divSpecTable.offsetHeight ) + "px";
      }

    },

    onConstrDeleteYes() {
      this.closeModal();
      this.DELETE_PRODUCT(this.cursor_pos.col);
      //this.CALCULATE_TOTAL();
      if (this.cursor_pos.col == this.cursor_pos.col_max){
        this.cursor_pos.decreaseCol(); 
      }
      this.cursor_pos.showCursor();
      this.ShowHideRow();
      //this.calculateResultSumm()
    },
    closeModal(){
      this.showModal_ConstrDel = false;
      this.showModal_ConstrDelPos = 1;
      this.showModal_AccountSave = false;
      this.showModal_AccountSavePos = 2;
      this.showModal_AccountSend = false;
      this.showModal_AccountSendPos = 0;
      this.showModal_AccountPrint = false;
      this.showModal_AccountPrintPos = 0;

      this.modeSet(this.mode_prev);

      if (this.nextFunction != null ){
        this.nextFunction(false);
        this.nextFunction = null;
      }
    },
    OnProductsSave(){
      this.onAccountProductsUpdate(false);
      this.nextFunction();
      this.nextFunction = null;
    },
    OnProductsNoSave(){
      this.nextFunction();
      this.nextFunction = null;
    },
    async OnProductsSend(){

      this.closeModal();
      if ( this.account_info.deal == "" ){ alert("Заполните номер сделки, без нее передача счета в КИС невозможна."); return; }
      if ( isNaN( this.account_info.deal ) ){ alert("Введите корректный номер сделки."); return; }

      this.START_ANIMATION();
      await this.RECALCULATE_TOTAL({thisobj:this});

      this.account_info.factory = "Union";
      await this.SEND_TO_KIS({ thisobj:this, account_info:this.account_info });

      this.forceRerender3();
      this.forceRerender4();
      //this.onAccountProductsUpdate(false);
      this.setInitialProducts();

    },
    ShowHideRow(){
      // скрыть строки


      for ( let i=0; i<this.cursor_pos.row_max; i++ ){
        let elements = document.querySelectorAll(`[data-cell-row="${i}"].is-hid`);
        if (elements.length == 0) elements = document.querySelectorAll(`[data-cell-row="${i}"].is-serv`);
        if (elements.length != 0){
          if (elements.length == this.cursor_pos.col_max) {
            for (let elem of elements) { elem.style.display = "none"; }
            let element = document.querySelector(`[properties_row_index="${i}"]`);
            if ( element != undefined ) element.style.display = "none";
          } else {
            elements = document.querySelectorAll(`[data-cell-row="${i}"]`);
            for (let elem of elements) { elem.style.display = ""; }
            let element = document.querySelector(`[properties_row_index="${i}"]`);
            if ( element != undefined ) element.style.display = "";
          }
        } else {
          elements = document.querySelectorAll(`[data-cell-row="${i}"]`);
          if (elements.length != 0){
            for (let elem of elements) { elem.style.display = ""; }
            let element = document.querySelector(`[properties_row_index="${i}"]`);
            if ( element != undefined ) element.style.display = "";
          }

        }
      }
    },
    onSpecViewClick(){
      this.onSpecViewFill();
      this.windowRefresh();
      this.saveAccountSettings();
      this.UPDATE_ACCOUNT({ account_info:this.account_info });
    },
    onImgViewClick(){
      this.windowRefresh();
      this.saveAccountSettings();
      this.UPDATE_ACCOUNT({ account_info:this.account_info });
    },
    onDrawViewClick(){
      this.windowRefresh();
      this.saveAccountSettings();
      this.UPDATE_ACCOUNT({ account_info:this.account_info });
    },
    onMenuViewClick(){
      this.windowRefresh();
      this.saveAccountSettings();
      this.UPDATE_ACCOUNT({ account_info:this.account_info });
    },
    onAccViewClick(){
      this.windowRefresh();
      this.saveAccountSettings();
      this.UPDATE_ACCOUNT({ account_info:this.account_info });
    },
    onCatViewClick(){
      this.windowRefresh();
      this.saveAccountSettings();
      this.UPDATE_ACCOUNT({ account_info:this.account_info });
    },
    onTableViewClick(){
      this.windowRefresh();
      this.saveAccountSettings();
      this.UPDATE_ACCOUNT({ account_info:this.account_info });
    },



    onSpecViewFill(){
      const checkedSpecView = document.getElementById("SpecView");
      if ( !checkedSpecView.checked ) return;
      const divSpecTable = document.getElementById("c-spec-table"); divSpecTable.innerHTML = "";
      let prod = this.productsGGG[this.cursor_pos.col-1].options['[SPEC]'];
      if ( prod == undefined ) return;
      
      const tbl = document.createElement("table");
      divSpecTable.appendChild(tbl);
      divSpecTable.style.overflow = "scroll";
      divSpecTable.style.height = '150px';
      let div = document.createElement("tr");
      let div1 = document.createElement("td"); div1.innerHTML = "Название"; div.appendChild(div1);
      div1 = document.createElement("td"); div1.innerHTML = "Кол."; div.appendChild(div1);
      div1 = document.createElement("td"); div1.innerHTML = ""; div.appendChild(div1);
      div1 = document.createElement("td"); div1.innerHTML = "Цена";  div.appendChild(div1);
      div1 = document.createElement("td"); div1.innerHTML = ""; div.appendChild(div1);
      div1 = document.createElement("td"); div1.innerHTML = "Сумма"; div.appendChild(div1);
      tbl.appendChild(div);

      for ( let i=0; i < prod.length; i++ ) {
        div = document.createElement("tr"); //div.className = "row";
        //div1.innerHTML = prod[i].name + ": " + prod[i].num + "x" + prod[i].price + "=" + Math.round( Number( prod[i].num ) * Number( prod[i].price ) ) + "руб.";
        div1 = document.createElement("td"); div1.innerHTML = prod[i].name; div.appendChild(div1);
        div1 = document.createElement("td"); div1.innerHTML = prod[i].num; div1.style.textAlign = 'right'; div.appendChild(div1);
        div1 = document.createElement("td"); div1.innerHTML = "x"; div.appendChild(div1);
        div1 = document.createElement("td"); div1.innerHTML = String( Intl.NumberFormat().format( prod[i].price ) ); div1.style.textAlign = 'right'; div.appendChild(div1);
        div1 = document.createElement("td"); div1.innerHTML = "="; div.appendChild(div1);
        div1 = document.createElement("td"); div1.innerHTML = String( Intl.NumberFormat().format( Math.round( Number( prod[i].num ) * Number( prod[i].price ) ) ) )+ " руб."; div1.style.textAlign = 'right'; div.appendChild(div1);
        //console.log("prod[i].num: ", i, prod[i].num);
        tbl.appendChild(div);
      }
    },

    DateStringToString( str ){
      //console.log( "DateStringToString на входе: ",str );
      let date = "",sep,arr,i;
      while ( typeof str == "string" ){
        if ( str.length != 10 ) break;
        i = str.indexOf("-"); if ( i != -1 ) sep = "-";
        i = str.indexOf("/"); if ( i != -1 ) sep = "/";
        i = str.indexOf("."); if ( i != -1 ) sep = ".";
        if ( sep == "" ) break;
        arr = str.split( sep ); if ( arr.length != 3 ) break;
        if ( arr[0].length == 4 && arr[1].length == 2 && arr[2].length == 2 ){
          date = arr[2] + "." + arr[1] + "." + arr[0];
        } else if ( arr[0].length == 2 && arr[1].length == 2 && arr[2].length == 4 ){
          date = arr[0] + "." + arr[1] + "." + arr[2];
        }
        break;
      }
      //console.log( "DateStringToString на выходе: ",date );
      return date;
    },

    OnRus(){
      this.langRUS = true; this.langENG = false; this.langIT = false;
      this.SET_LANGUAGE("rus");
    },
    OnEng(){
      this.langRUS = false; this.langENG = true; this.langIT = false;
      this.SET_LANGUAGE("eng");
    },
    OnIt(){
      this.langRUS = false; this.langENG = false; this.langIT = true;
      this.SET_LANGUAGE("it");
    },

    loadAccountSettings() {
      let eflag = false;
      if ( this.account_info.extendinfo != undefined && this.account_info.extendinfo != "" ){
        let einfo = this.account_info.extendinfo.split(";");
        for ( let i=0; i<einfo.length; i++ ){
          let arr = einfo[i].split(":"), checked;
          if ( arr.length == 2 ){
            switch (arr[0]){
              case "Spec":  checked = document.getElementById("SpecView"); break;  // кнопка спецификации
              case "Img":   checked = document.getElementById("ImgView");  break;  // кнопка изображения
              case "Draw":  checked = document.getElementById("DrawView"); break;  // кнопка чертежа
              case "Menu":  checked = document.getElementById("MenuView"); break;  // кнопка чертежа
              case "Acc":   checked = document.getElementById("AccView"); break;   // кнопка счета
              case "Cat":   checked = document.getElementById("CatView"); break;   // кнопка каталога
              case "Table": checked = document.getElementById("TableView"); break; // кнопка основной таблицы
              default: checked = undefined;
            }
            if ( checked != undefined ){
              checked.checked = arr[1]==1 ? true : false;
              eflag = true;
              //checked.style.display = arr[1] ? "block" : "none";
            }
          }
        }
      } 
      if ( !eflag ) {
        const checkedMenuView = document.getElementById("MenuView"); checkedMenuView.checked = true;
        const checkedAccView = document.getElementById("AccView"); checkedAccView.checked = true;
      }
      const checked1 = document.getElementById("CatView");
      const checked2 = document.getElementById("TableView");
      if ( !checked1.checked && !checked2.checked ) checked2.checked = true;

      //---------- настройки печати
      if ( this.account_info.printinfo != undefined && this.account_info.printinfo != "" ){
        let pinfo = this.account_info.printinfo.split(";");
        for ( let i=0; i<pinfo.length; i++ ){
          let arr = pinfo[i].split(":");
          if ( arr.length == 2 ){
            switch (arr[0]){
              case "CP":    this.print_info.cp = arr[1]==1 ? true : false; break;      // кнопка КП
              case "CPTtl": this.print_info.cptotal = arr[1]==1 ? true : false; break; // кнопка ИТОГО
              case "CPOpn": this.print_info.cpopen = arr[1]==1 ? true : false; break;  // кнопка схемы открывания
              case "App":   this.print_info.append = arr[1]==1 ? true : false; break;  // кнопка приложения
              case "Tech":  this.print_info.tech = arr[1]==1 ? true : false; break;    // кнопка технички
            }
          }
        }
      }

    },
    saveAccountSettings() {
      const checkedSpecView = document.getElementById("SpecView");      // кнопка спецификации
      const checkedImgView = document.getElementById("ImgView");        // кнопка изображения
      const checkedDrawView = document.getElementById("DrawView");      // кнопка чертежа
      const checkedMenuView = document.getElementById("MenuView");      // кнопка меню
      const checkedAccView = document.getElementById("AccView");        // кнопка счета
      const checkedCatView = document.getElementById("CatView");        // кнопка каталога
      const checkedTableView = document.getElementById("TableView");    // кнопка основной таблицы

      let str = "", str1 = "";
      str1 = checkedSpecView.checked ? "1":"0";
      str = str + "Spec:" + str1;
      str1 = checkedImgView.checked ? "1":"0";
      str = str + ";Img:" + str1;
      str1 = checkedDrawView.checked ? "1":"0";
      str = str + ";Draw:" + str1;
      str1 = checkedMenuView.checked ? "1":"0";
      str = str + ";Menu:" + str1;
      str1 = checkedAccView.checked ? "1":"0";
      str = str + ";Acc:" + str1;
      str1 = checkedCatView.checked ? "1":"0";
      str = str + ";Cat:" + str1;
      str1 = checkedTableView.checked ? "1":"0";
      str = str + ";Table:" + str1;

      this.account_info.extendinfo = str;

      this.save_print_info();
    },

    save_print_info(){
      let str = "", str1 = "";
      str1 = this.print_info.cp ? "1":"0";
      str = str + "CP:" + str1;
      str1 = this.print_info.cptotal ? "1":"0";
      str = str + ";CPTtl:" + str1;
      str1 = this.print_info.cpopen ? "1":"0";
      str = str + ";CPOpn:" + str1;
      str1 = this.print_info.append ? "1":"0";
      str = str + ";App:" + str1;
      str1 = this.print_info.tech ? "1":"0";
      str = str + ";Tech:" + str1;

      this.account_info.printinfo = str;
    },

  },
  async created() {
    //alert("created");


    let lang = await new Promise(resolve => this.GET_LANGUAGE( resolve ));
    if (lang == "rus") this.OnRus();
    if (lang == "eng") this.OnEng();
    if (lang == "it") this.OnIt();

    this.START_ANIMATION();
    await this.FETCH_PROPERTIES( {thisobj:this, factory_name:this.account_info.factory} );
    await this.fetchProductsWithOptions( this.account_info.account_id );
    await this.FETCH_TREE( { thisobj:this, mode:this.tree_on, num_node:"1", filter:"" } );

    this.cursor_pos.row_max = this.properties.key.length;
    this.cursor_pos.col_max = this.productsGGG.length;
    this.cat_cursor_pos.col_max = 6;
    this.cat_cursor_pos.row_max = this.catalog.length;
    //this.tree_cursor_pos.col_max = 5;
    //this.tree_cursor_pos.row_max = this.tree.length;

    //this.cursor_pos.showCursor();

    this.loadAccountSettings();
    this.setPicture();
    this.setDraw();
    this.ShowHideRow();
    this.onSpecViewFill();

    /*this.onSpecViewClick();
    this.onImgViewClick();
    this.onMenuViewClick);
    this.onAccViewClick();*/

    this.windowRefresh();

    this.SET_ACCOUNT_INFO(this.account_info);
    this.CALCULATE_TOTAL();
    await this.FETCH_PRODUCT_OPTIONS_CHANGED({thisobj:this});
    //this.calculateResultSumm();

    this.setInitialProducts();

    if ( !this.modeSet("table","8") ) this.modeSet("catalog");
    /*const flexTable = document.getElementById("c-flex-table");
    const divCatView = document.getElementById("divCatView");
    if ( flexTable != undefined && divCatView != undefined ){
      if ( flexTable.style.display == 'none' && divCatView.style.display == '' ) { this.cat_cursor_pos.col = 2; this.modeSet("catalog"); }
    }*/

    this.STOP_ANIMATION();

    window.addEventListener("keydown", this.keyboardListener);
    window.addEventListener("resize", this.windowRefresh);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.windowRefresh);
    window.removeEventListener("keydown", this.keyboardListener);
  },
  beforeRouteLeave (to, from , next) {
    //let curr_account = [{account:this._props.account_info.account, account_id:this._props.account_info.account_id, customer:this._props.account_info.customer}];
    //let curr_account = [{ account_info: this._props.account_info }];

    if ( this.no_save ) next();
    if ( this.account_info.locked =='1' ) next();

    let curr_account = [ this._props.account_info ];
    if( isEqualProductsArray( this.productsGGG, this.initial_products ) && 
        isEqualProductsArray( this.catalog, this.initial_catalog ) && 
        isEqualProductsArray( curr_account, this.initial_account ) ){

      //console.log( "this.showModal_AccountSave",this.showModal_AccountSave );
      next();

    } else {
      //this.showModal();
      //const answer = window.confirm('При выходе изменения не будут записаны. Нажмите "Записать" перед выходом, если хотите, чтобы изменения сохранились.');

      this.nextFunction = next;
      this.showModal_AccountSave = true;
      this.modeSet("accountSave");

      /*const answer=false;
      if (answer){
        next();
      } else {
        next(false);
      }*/
    }
  },
  updated(){
    this.setPicture();
    this.setDraw();
    this.ShowHideRow();
    if ( this.cursor_pos.increaseRowFlag ){
      this.cursor_pos.increaseRowFlag --;
      //this.cursor_pos.increaseRow();
      if ( !this.cursor_pos.increaseRowFlag ){
        this.onProductPressArrowDown();
      }
    }

    this.showAllCursor();
    /*this.$nextTick(function(){
    });*/

    //this.cursor_pos.cursorPosCheck( this );
  },

}; 

function isEqualProductsArray( firstArr, secondArr ) {
  //alert("isEqualProductsArray");
  if( firstArr.length != secondArr.length ){ return false; }
  firstArr = JSON.parse( JSON.stringify( firstArr ) );
  secondArr = JSON.parse( JSON.stringify( secondArr ) );
  for( let i = 0; i < firstArr.length; i++ ) {
    let curFirstProduct = firstArr[i];
    let curSecondProduct = secondArr[i];
    if ( curFirstProduct == null && curSecondProduct == null ){ continue; }
    if ( curFirstProduct == null && curSecondProduct != null ){ return false; }
    if ( curFirstProduct != null && curSecondProduct == null ){ return false; }

    if ( curFirstProduct.hasOwnProperty( 'options' ) ){
      delete curFirstProduct.options;
    }
    if ( curSecondProduct.hasOwnProperty( 'options' ) ){
      delete curSecondProduct.options;
    }

    if( Object.keys( curFirstProduct ).length != Object.keys( curSecondProduct ).length ){ return false; }
    for ( const property in curFirstProduct ){
      if( !curSecondProduct.hasOwnProperty( property ) ){ return false; }
      //if ( property == "account_info" || property == "constr_group" || property == "elem"){
      if ( typeof(curFirstProduct[property]) == "object" && typeof(curSecondProduct[property]) == "object" ){
        if ( !isEqualProductsArray( [curFirstProduct[property]], [curSecondProduct[property]] ) ){ return false; }
      } else if( curFirstProduct[property] != curSecondProduct[property] ) {
        return false;
      }
    }
  }
  return true;
}

function isPropertiesNumeric(obj,key){
  let index = obj.properties.key.indexOf(key);
  if ( index!=-1 ){
    if (obj.properties.isNumeric[index]=="1") return true; else return false;
  }
  return false;
}

function isPropertiesEditable(obj,key){
  let index = obj.properties.key.indexOf(key);
  if ( index!=-1 ){
    if (obj.properties.isNumeric[index]=="1" || obj.properties.isText[index]=="1") return true; else return false;
  }
  return false;
}

function isEmpty( val ){
  if ( val == undefined ) return true;
  if ( val == null ) return true;
  if ( typeof val == "string" &&  val == "" ) return true;
  return false;
}

</script>

<style scoped>

  .c-input {
    min-height: 20px;
    height: 20px;
    padding: 10px 15px;    
    /*font-size:x-large;*/
    font-size:16px;
    border:none;
    }
  .c-input-readonly {
    min-height: 20px;
    height: 20px;
    padding: 10px 15px;    
    /*font-size:x-large;*/
    font-size:16px;
    border:none;
    /*background: #e9e7df;*/
    background: #f6f5f0;
    /*background-color: #9e9e9e;*/
    }

  .c-spec-table, .c-acc-table{
    display: inline-block;
    width: calc(100vw-200px); /*- 250px*/
    }

  .c-flex-table {
    display: flex;
    overflow: scroll;
    height: calc(100vh - 200px); /*200*/
    /*width: 1700px;*/
    /*width: calc(100vw - 60px);*/
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(0,0,0,.1),0 2px 2px 0 rgba(0,0,0,.2);
    /*scroll-behavior: smooth;*/
    scrollbar-width: thin;
    }

  /*.divImgView{
    height: "300px";
    vertical-align: middle;
  }*/

  .c-main-table{
    display: flex;
    overflow: hidden;
    /*overflow: scroll;*/
    /*height: calc(100vh - 200px);*/ /*200*/
    background: #e9e7df;
    /*box-shadow: 0 0 2px 0 rgba(0,0,0,.1),0 2px 2px 0 rgba(0,0,0,.2);*/
    /*scroll-behavior: smooth;*/
    /*scrollbar-width: thin;*/
  }

  .c-flex-table.is-dropdown-opened {
    overflow: hidden;
  }
  .c-flex-table__column {
    display: flex;
    flex-flow: column;
    height: max-content;
  }
  .c-flex-table__column.c-flex-table__column--sticky-horizontal {
    position: sticky;
    z-index: 3;
    left: 0;
  }
  #table__column--sticky-horizontal-1 {
    /*left: 48.5px; */
    left: 0;
  }
  #table__column--sticky-horizontal-2 {
    left: calc(48.5px + 168px);
  }
  .c-flex-table__data, .c-flex-table__header, .c-flex-table__header_cat, .c-flex-table__data_cat {
    position: relative;
    display: flex;
    min-height: 15px; /*25*/
    height: 15px; /*25*/
    padding: 5px 5px; /*10 15*/
    justify-content: center;
    align-items: center;
    align-content: center;
    border: .5px solid rgba(120, 141, 152, .15);
    white-space: nowrap;
  }
  .c-flex-table__header {
    height: 35px; /*25*/
    max-width: 500px;
  }
  .c-flex-table__header_cat {
    height: 35px;
  }
  .c-flex-table__data {
    background: #fff;
    user-select: none;
    max-width: 500px;
    /*text-wrap: wrap;*/
  }
  .c-flex-table__data_cat {
    background: #fff;
    user-select: none;
  }
  .c-flex-table__data:hover {
    /*background: #fff1d7;*/
    /*background: #f0f0f0; цвет курсора мыши */
    cursor: pointer;
  }
  .c-flex-table__header, .c-flex-table__header_cat {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 2;
    font-weight: 500; /* 500 */
  }
  .c-flex-table__data.is-req {
    /*background: #a8d8ff;*/
    /*background: #c9e5fc;*/
    background: #daf5ff;
  }
  .c-flex-table__data.is-hid,.c-flex-table__data_cat.is-hid {
    /*background: #dadada;*/
    background: #e9e7df;
  }
  .c-flex-table__data.is-serv {
    /*background: #dadada;*/
    background: #e9e7df;
  }
  .c-flex-table__data.is-dis {
    /*background: #dadada;*/
    background: #dfe9e0;
  }

  .c-flex-table__header.is-grp1 {
    background: #44caff;
  }
  .c-flex-table__header.is-grp2 {
    background: #44ff98;
    /*background: #94e1ff;*/
  }
  .c-flex-table__header.is-err {
    background: #ff0000;
  }

  .c-flex-table__header.is-cha {
    background: #ffff00;
  }

  .c-flex-table__data.is-active,
  .c-flex-table__data_cat.is-cat-active,
  .c-flex-table__data_cat.is-tree-active {
    background:#ffcd70;
    /*background: #dadada;*/
  }

  .c-flex-table__header.is-pass,
  .c-flex-table__header_cat.is-pass,
  .c-flex-table__data.is-pass,
  .c-flex-table__data_cat.is-pass,
  .c-flex-table__data.c-flex-table__data--sideheader.is-pass,
  .c-flex-table.is-pass,
  .c-flex-table__column.is-pass, .is-pass {

    color: #b8b8b8;
  }

  .c-flex-table__data.c-flex-table__data--sideheader,
  .c-flex-table__header, .c-flex-table__header_cat {
    /*background:#d4eaf8;*/
    /*background: #bbbbbb;*/
    /*background: #dadada;*/
    /*background: #f3f1e2;*/    /* фон в таблице */
    background: #e9e7df;
  }
  .c-select-dropdown__option.is-focus, .c-select-dropdown__option:hover {
    background:#ffcd70; /* цвет курсора мыши */
  }
  .c-flex-table__select-dropdown {
    position: absolute;
    width: 100%;
    height: 0;
    overflow-y: hidden;
    visibility: hidden;
    top: 44px;
    left: -.5%;
    z-index: 1;
    border: .5px solid rgba(120, 141, 152, .15);
    box-shadow: 0 0 2px 0 rgba(0,0,0,.1),0 2px 2px 0 rgba(0,0,0,.2);
    background: #fff;
    /*scroll-behavior: smooth;*/
  }
  .c-flex-table__select-dropdown.is-active2 {
    visibility: visible;
  }
  .c-select-dropdown__option {
    padding: 5px 10px;
    cursor: pointer;
  }
  input:invalid {
    background-color: lightpink;
  }

  .tooltip-inner {
    white-space: pre-wrap;
  }

</style>
